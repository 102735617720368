/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:ital,wght@0,700;1,700&family=Lekton&display=swap'); */

body {
  line-height: 1.7;
  color: #8c92a0;
  font-weight: 300;
  font-size: 16px; 
  /* font-family: 'IBM Plex Sans Condensed', sans-serif !important; */
}



  .carrousel-item {
    /* width: 600px;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    /* width: 95%; */
    width: 100%;
    /* width: 81%; */
    position: absolute;
    opacity: 0;
    transition: opacity 1s;
    overflow: hidden;
  }
  
/* #carrouselimg1{
  display: none;
} */
#carrouselimg2{
  display: none;
}
#carrouselimg3{
  display: none;
}
#carrouselimg4{
  display: none;
}
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  
  .center5 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 190px;
  }
  .center1 {
    width : 100%;
    height: 300px;
  }
  .center11 {
    width : 100%;
    height: 190px;
  }

  .navbar-light {
    background-color: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  }
  
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  .custom-control-label {
    font-weight: 400;
  }
  
  .forgot-password,
  .forgot-password a {
    text-align: right;
    font-size: 13px;
    padding-top: 10px;
    color: #7f7d7d;
    margin: 0;
  }
  
  .forgot-password a {
    color: #167bff;
  }










::-moz-selection {
  background: #000;
  color: #fff; }

::selection {
  background: #000;
  color: #fff; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none; }

.text-black {
  color: #000; }

.site-wrap:before {
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden; }

.offcanvas-menu .site-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: hidden; }
  .offcanvas-menu .site-wrap:before {
    opacity: 1;
    visibility: visible; }

.btn {
  text-transform: uppercase;
  position: relative;
  -webkit-transition: 0.2s all ease-in-out !important;
  -o-transition: 0.2s all ease-in-out !important;
  transition: 0.2s all ease-in-out !important;
  top: 0;
  letter-spacing: .05em; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .btn.btn-secondary {
    background-color: #e6e7e9;
    border-color: #e6e7e9;
    color: #000; }
  .btn.btn-sm {
    font-size: 12px !important;
    font-weight: 700 !important;
    /* padding: 10px 20px !important; */
   }
  .btn.btn-primary {
    font-size: 17px;
    font-weight: 300;
    letter-spacing: .2em; }
  .btn:hover {
    -webkit-box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    box-shadow: 0 5px 20px -7px rgba(0, 0, 0, 0.9) !important;
    top: -2px; }

.form-control {
  height: 43px; }
  .form-control:active, .form-control:focus {
    border-color: #7971ea; }
  .form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }

.site-section {
  padding: 2.5em 0; }
  @media (min-width: 768px) {
    .site-section {
      padding: 5em 0; } }
  .site-section.site-section-sm {
    padding: 4em 0; }

.site-section-heading {
  font-size: 30px;
  color: #25262a;
  position: relative; }
  .site-section-heading:before {
    content: "";
    left: 0%;
    top: 0;
    position: absolute;
    width: 40px;
    height: 2px;
    background: #7971ea; }
  .site-section-heading.text-center:before {
    content: "";
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;
    width: 40px;
    height: 2px;
    background: #7971ea; }

.border-top {
  border-top: 1px solid #edf0f5 !important; }

.site-footer {
  padding: 4em 0; }
  .site-footer ul li {
    margin-bottom: 10px; }
    .site-footer ul li a {
      color: #5c626e; }
      .site-footer ul li a:hover {
        color: #7971ea; }
  .site-footer .footer-heading {
    font-size: 20px;
    color: #25262a; }

/* Navbar */
.site-navbar {
  background: #fff;
  margin-bottom: 0px;
  z-index: 1999;
  position: relative; }
  .site-navbar.transparent {
    background: transparent; }
  .site-navbar .site-navbar-top {
    /* border-bottom: 1px solid #f3f3f4; */
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px; }
    @media (min-width: 768px) {
      .site-navbar .site-navbar-top {
        padding-top: 40px;
        padding-bottom: 40px; } }
  .site-navbar .site-search-icon a span {
    display: inline-block;
    margin-right: 10px; }
  .site-navbar .site-search-icon a:hover {
    text-decoration: none; }
  .site-navbar .site-logo a {
    text-transform: uppercase;
    color: #25262a;
    letter-spacing: .2em;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    /* border: 2px solid #25262a; */
   }
    .site-navbar .site-logo a:hover {
      text-decoration: none; }
  .site-navbar a {
    color: #868995; }
    .site-navbar a:hover {
      color: #3d3f45; }
  .site-navbar .icon {
    font-size: 20px; }
  .site-navbar .site-top-icons ul, .site-navbar .site-top-icons ul li {
    padding: 0;
    margin: 0;
    list-style: none; }
  .site-navbar .site-top-icons ul li {
    display: inline-block; }
    .site-navbar .site-top-icons ul li a {
      margin-right: 10px; }
      .site-navbar .site-top-icons ul li a.site-cart {
        display: block;
        position: relative; }
        .site-navbar .site-top-icons ul li a.site-cart .count {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -15px;
          margin-top: -20px;
          font-size: 13px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          border-radius: 50%;
          display: block;
          text-align: center;
          background: black;
          color:white;
          -webkit-transition: .2s all ease-in-out;
          -o-transition: .2s all ease-in-out;
          transition: .2s all ease-in-out; }
      .site-navbar .site-top-icons ul li a:hover .count {
        -webkit-box-shadow: 0 3px 10px -4px rgba(0, 0, 0, 0.3) !important;
        box-shadow: 0 3px 10px -4px rgba(0, 0, 0, 0.3) !important;
        margin-top: -22px; }
    .site-navbar .site-top-icons ul li:last-child a {
      padding-right: 0; }
  .site-navbar .site-top-icons ul .site-menu-toggle {
    font-size: 25px;
    position: relative;
    top: 2px;
    margin-left: 20px; }
  .site-navbar .site-navigation.border-bottom {
    border-bottom: 1px solid #f3f3f4 !important; }
  .site-navbar .site-navigation .site-menu {
    margin-bottom: 0; }
    .site-navbar .site-navigation .site-menu .active > a {
      color: #7971ea; }
    .site-navbar .site-navigation .site-menu a {
      text-decoration: none !important;
      font-size: 15px;
      display: inline-block; }
    .site-navbar .site-navigation .site-menu > li {
      display: inline-block;
      padding: 6px 16px; }
     
      .site-navbar .site-navigation .site-menu > li > a {
        /* padding: 10px 10px; */
        text-transform: uppercase;
        letter-spacing: .05em;
        color: white;
        font-size: 14px;
        font-weight: bold;

        text-decoration: none !important; } 
        @media (max-width: 768px) {
        .site-navbar .site-navigation .site-menu > li > a {
          /* padding: 10px 4px; */
          text-transform: uppercase;
          /* letter-spacing: .05em; */
          color: white;
          font-size: 14px;
          font-weight: bold;
        }
        .site-navigation  {
          margin-left : -60px !important;
          padding-right:  60px !important;
           }
      }
        .site-navbar .site-navigation .site-menu > li > a:hover {
          color: #7971ea; }
    .site-navbar .site-navigation .site-menu .has-children {
      position: relative; }
      .site-navbar .site-navigation .site-menu .has-children > a {
        position: relative;
        padding-right: 20px; }
        .site-navbar .site-navigation .site-menu .has-children > a:before {
          position: absolute;
          content: "\e313";
          font-size: 16px;
          top: 50%;
          right: 0;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          font-family: 'icomoon'; }
      .site-navbar .site-navigation .site-menu .has-children .dropdown {
        visibility: hidden;
        opacity: 0;
        top: 100%;
        position: absolute;
        text-align: left;
        border-top: 2px solid #7971ea;
        -webkit-box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0px 4px 0px rgba(0, 0, 0, 0.05);
        border-left: 1px solid #edf0f5;
        border-right: 1px solid #edf0f5;
        border-bottom: 1px solid #edf0f5;
        padding: 0px 0;
        margin-top: 20px;
        margin-left: 0px;
        background: #fff;
        -webkit-transition: 0.2s 0s;
        -o-transition: 0.2s 0s;
        transition: 0.2s 0s; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown a {
          font-size: 16px;
          text-transform: none;
          letter-spacing: normal;
          -webkit-transition: 0s all;
          -o-transition: 0s all;
          transition: 0s all; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown .active > a {
          color: #7971ea !important; }
        .site-navbar .site-navigation .site-menu .has-children .dropdown > li {
          list-style: none;
          padding: 0;
          margin: 0;
          min-width: 200px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a {
            padding: 9px 20px;
            display: block; }
            .site-navbar .site-navigation .site-menu .has-children .dropdown > li > a:hover {
              background: #f4f5f9;
              color: #25262a; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > a:before {
            content: "\e315";
            right: 20px; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > .dropdown, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children > ul {
            left: 100%;
            top: 0; }
          .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:active > a, .site-navbar .site-navigation .site-menu .has-children .dropdown > li.has-children:focus > a {
            background: #f4f5f9;
            color: #25262a; }
      .site-navbar .site-navigation .site-menu .has-children a {
        text-transform: uppercase; }
      .site-navbar .site-navigation .site-menu .has-children:hover > a, .site-navbar .site-navigation .site-menu .has-children:focus > a, .site-navbar .site-navigation .site-menu .has-children:active > a {
        color: #7971ea; }
      .site-navbar .site-navigation .site-menu .has-children:hover, .site-navbar .site-navigation .site-menu .has-children:focus, .site-navbar .site-navigation .site-menu .has-children:active {
        cursor: pointer; }
        .site-navbar .site-navigation .site-menu .has-children:hover > .dropdown, .site-navbar .site-navigation .site-menu .has-children:focus > .dropdown, .site-navbar .site-navigation .site-menu .has-children:active > .dropdown {
          -webkit-transition-delay: 0s;
          -o-transition-delay: 0s;
          transition-delay: 0s;
          margin-top: 0px;
          visibility: visible;
          opacity: 1; }

.site-mobile-menu {
  width: 300px;
  position: fixed;
  right: 0;
  z-index: 2000;
  padding-top: 20px;
  background: #fff;
  height: calc(100vh);
  -webkit-transform: translateX(110%);
  -ms-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 0 20px -10px rgba(0, 0, 0, 0.1);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }
  .offcanvas-menu .site-mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }
  .site-mobile-menu .site-mobile-menu-header {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close {
      float: right;
      margin-top: 8px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span {
        font-size: 40px;
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 1;
        cursor: pointer;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-close span:hover {
          color: #25262a; }
    .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo {
      float: left;
      margin-top: 10px;
      margin-left: 20px; }
      .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a {
        display: inline-block;
        text-transform: uppercase;
        color: #25262a;
        letter-spacing: .2em;
        font-size: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px solid #25262a; }
        .site-mobile-menu .site-mobile-menu-header .site-mobile-menu-logo a:hover {
          text-decoration: none; }
  .site-mobile-menu .site-mobile-menu-body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 20px;
    height: calc(100vh - 52px);
    padding-bottom: 150px; }
  .site-mobile-menu .site-nav-wrap {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative; }
    .site-mobile-menu .site-nav-wrap a {
      padding: 10px 20px;
      display: block;
      position: relative;
      color: #212529; }
      .site-mobile-menu .site-nav-wrap a:hover {
        color: #7971ea; }
    .site-mobile-menu .site-nav-wrap li {
      position: relative;
      display: block; }
      .site-mobile-menu .site-nav-wrap li.active > a {
        color: #7971ea; }
    .site-mobile-menu .site-nav-wrap .arrow-collapse {
      position: absolute;
      right: 0px;
      top: 10px;
      z-index: 20;
      width: 36px;
      height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 50%; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:hover {
        background: #f8f9fa; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse:before {
        font-size: 12px;
        z-index: 20;
        font-family: "FontAwesome";
        content: "\f078";
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-180deg);
        -ms-transform: translate(-50%, -50%) rotate(-180deg);
        transform: translate(-50%, -50%) rotate(-180deg);
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease; }
      .site-mobile-menu .site-nav-wrap .arrow-collapse.collapsed:before {
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
    .site-mobile-menu .site-nav-wrap > li {
      display: block;
      position: relative;
      float: left;
      width: 100%; }
      .site-mobile-menu .site-nav-wrap > li > a {
        padding-left: 20px;
        font-size: 20px; }
      .site-mobile-menu .site-nav-wrap > li > ul {
        padding: 0;
        margin: 0;
        list-style: none; }
        .site-mobile-menu .site-nav-wrap > li > ul > li {
          display: block; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > a {
            padding-left: 40px;
            font-size: 16px; }
          .site-mobile-menu .site-nav-wrap > li > ul > li > ul {
            padding: 0;
            margin: 0; }
            .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li {
              display: block; }
              .site-mobile-menu .site-nav-wrap > li > ul > li > ul > li > a {
                font-size: 16px;
                padding-left: 60px; }

/* Blocks */
.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .site-blocks-cover, .site-blocks-cover .row {
    min-height: 600px;
    height: calc(100vh - 174px); }
  .site-blocks-cover h1 {
    font-size: 30px;
    font-weight: 900;
    color: #000; }
    @media (min-width: 768px) {
      .site-blocks-cover h1 {
        font-size: 50px; } }
  .site-blocks-cover p {
    color: #333333;
    font-size: 20px;
    line-height: 35px; }
  .site-blocks-cover .intro-text {
    font-size: 16px;
    line-height: 1.5; }

.site-blocks-1 {
  border-bottom: 1px solid #edf0f5; }
  .site-blocks-1 .divider {
    position: relative; }
    .site-blocks-1 .divider:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      right: 10px;
      background: #edf0f5; }
    .site-blocks-1 .divider:last-child:after {
      display: none; }
  .site-blocks-1 .icon span {
    position: relative;
    color: #7971ea;
    top: -10px;
    font-size: 50px;
    display: inline-block; }
  .site-blocks-1 .text h2 {
    color: #25262a;
    letter-spacing: .05em;
    font-size: 18px; }
  .site-blocks-1 .text p:last-child {
    margin-bottom: 0; }

.site-blocks-2 .block-2-item {
  display: block;
  position: relative; }
  .site-blocks-2 .block-2-item:before {
    z-index: 1;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
    background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); }
  .site-blocks-2 .block-2-item .image {
    position: relative;
    margin-bottom: 0;
    overflow: hidden; }
    .site-blocks-2 .block-2-item .image img {
      margin-bottom: 0;
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out; }
  .site-blocks-2 .block-2-item .text {
    z-index: 2;
    bottom: 0;
    padding-left: 20px;
    position: absolute;
    width: 100%; }
    .site-blocks-2 .block-2-item .text > span, .site-blocks-2 .block-2-item .text h3 {
      color: #fff; }
    .site-blocks-2 .block-2-item .text > span {
      font-size: 12px;
      letter-spacing: .1em;
      font-weight: 900; }
    .site-blocks-2 .block-2-item .text h3 {
      font-size: 40px; }
  .site-blocks-2 .block-2-item:hover .image img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }

.block-3 .owl-stage {
  padding-top: 40px;
  padding-bottom: 40px; }

.block-3 .owl-nav {
  position: relative;
  position: absolute;
  bottom: -50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }
  .block-3 .owl-nav .owl-prev, .block-3 .owl-nav .owl-next {
    position: relative;
    display: inline-block;
    padding: 20px;
    font-size: 30px;
    color: #5c626e; }
    .block-3 .owl-nav .owl-prev:hover, .block-3 .owl-nav .owl-next:hover {
      color: #25262a; }
    .block-3 .owl-nav .owl-prev.disabled, .block-3 .owl-nav .owl-next.disabled {
      opacity: .2; }

.block-4 {
  -webkit-box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.1);
  background: #fff; }
  .block-4 .block-4-text h3 {
    font-size: 20px;
    margin-bottom: 0; }
    .block-4 .block-4-text h3 a {
      text-decoration: none; }

.block-5 ul, .block-5 ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5; }

.block-5 ul li {
  padding-left: 30px;
  position: relative;
  margin-bottom: 15px;
  color: #25262a; }
  .block-5 ul li:before {
    top: 0;
    font-family: "icomoon";
    content: "";
    position: absolute;
    left: 0;
    font-size: 20px;
    line-height: 1;
    color: #7971ea; }
  .block-5 ul li.address:before {
    content: "\e8b4"; }
  .block-5 ul li.email:before {
    content: "\f0e0"; }
  .block-5 ul li.phone:before {
    content: "\f095"; }

.block-6 {
  display: block; }
  .block-6 img {
    display: block; }
  .block-6 h3 {
    font-size: 18px; }
  .block-6 p {
    color: #737b8a; }

.block-7 .form-group {
  position: relative; }

.block-7 .form-control {
  padding-right: 96px; }

.block-7 .btn {
  position: absolute;
  width: 80px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 3px; }

.block-8 .post-meta {
  color: #c4c7ce; }

.block-8 .block-8-sep {
  margin-left: 10px;
  margin-right: 10px; }

.site-blocks-table {
  overflow: auto; }
  .site-blocks-table .product-thumbnail {
    width: 200px; }
  .site-blocks-table thead th {
    padding: 30px;
    text-align: center;
    border-width: 1px !important;
    vertical-align: middle;
    color: #212529;
    font-size: 18px; }
  .site-blocks-table td {
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    color: #212529; }
  .site-blocks-table tbody tr:first-child td {
    border-top: 1px solid #7971ea !important; }

.site-block-order-table th {
  border-top: none !important;
  border-bottom-width: 1px !important; }

.site-block-order-table td, .site-block-order-table th {
  color: #000; }

.site-block-top-search {
  position: relative; }
  .site-block-top-search .icon {
    position: absolute;
    right: -2.5%;
    top: 39%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .site-block-top-search input {
    padding-left: 40px;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
    .site-block-top-search input:focus, .site-block-top-search input:active {
      padding-left: 25px; }

.site-block-27 ul, .site-block-27 ul li {
  padding: 0;
  margin: 0; }

.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px; }
  .site-block-27 ul li a, .site-block-27 ul li span {
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc; }
  .site-block-27 ul li.active a, .site-block-27 ul li.active span {
    background: #7971ea;
    color: #fff;
    border: 1px solid transparent; }

#slider-range {
  height: 8px; }
  #slider-range .ui-slider-handle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none !important;
    background: #7971ea; }
    #slider-range .ui-slider-handle:focus, #slider-range .ui-slider-handle:active {
      outline: none; }
  #slider-range .ui-slider-range {
    background-color: #7971ea; }

.color-item .color {
  width: 14px;
  height: 14px; }

.block-16 figure {
  position: relative; }
  .block-16 figure .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 40px;
    width: 90px;
    height: 90px;
    background: #fff;
    display: block;
    border-radius: 50%;
    border: none; }
    .block-16 figure .play-button:hover {
      opacity: 1; }
    .block-16 figure .play-button > span {
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translate(-50%, -45%);
      -ms-transform: translate(-50%, -45%);
      transform: translate(-50%, -45%); }

.block-38 .block-38-header .block-38-heading {
  color: #000;
  margin: 0;
  font-weight: 300; }

.block-38 .block-38-header .block-38-subheading {
  color: #b3b3b3;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: .1em; }

.block-38 .block-38-header img {
  width: 120px;
  border-radius: 50%;
  margin-bottom: 20px; }


  @media  (max-width: 996px) {
    .block-none-cato{
  display:  none;
    }
  }

  .logo-img{
    width:350px;
    height:150px;
  }
  .js-logo-clone{
    /* padding: 28px 7px; */
  }
  .site-navbar .site-logo a{
    /* width:350px; */
    height:150px !important;
    padding :  60px 20px;
  }
  
  /* @media  (max-width: 400px) {
    .js-logo-clone{
      padding: 15px 7px;
    }
    .logo-img{
      width:200px;
      height:50px;
    }
  } */

  /* Admin */

.admin-data-container{
    /* width : 220px ; */
    background : rgb(74, 76, 78);
    height : 150px;
    margin :  3px 7px;

}

.admin-card{
  margin : 5px 0px
}
.site-section-heading:before {
  left: 45%  !important;
}


.color-th{
  color :  black
}


.pop-up-1{
    background : black ;
    color : white ;
    width : 500px;
    padding : 5px 10px ;
    border-radius : 10px ;
    margin :  5px ;
    position : fixed ;
    top : 40%;
    left : 32% ;
    z-index : 99999 ;
    visibility: hidden;
    opacity: 0.4;
}




@media  (max-width: 500px) {

  .pop-up-1{
    background : black ;
    color : white ;
    width : 300px;
    padding : 5px 10px ;
    border-radius : 10px ;
    margin :  5px ;
    position : fixed ;
    top : 30% !important;
    left : 8% !important;
    /* top : 60%; */
    /* left : 32% ; */
    z-index : 99999 ;
    visibility: hidden;
    opacity: 0.7;
}

}













.change-header-color{
  color:#167bff;
  font-size: 28px !important;
  transition:  .4s;
}

.change-header-color33{
  color:#167bff;
  font-size: 28px !important;
  transition:  .4s;
  display: none;
}

/* .change-header-color1{ */
  /* color:rgb(238, 2, 138) !important; */
/* } */



.change-header-color{
  /* color:rgb(0, 240, 255) !important; */
  color: #000 !important;
}

/* .change-header-color1:hover{
  color:rgb(0, 240, 255);
} */


.arrival{
  overflow: hidden;
  max-width : 600px !important;
  /* padding-left : 40px; */
  /* image-resolution: inherit; */
  height : 500px !important;
  margin: 20px 10px !important;

  /* margin : 15px 10px;
  width : 600px;
  height:500px;    
  overflow: hidden; */

  /* max-width : 650px;
  overflow: hidden; */

}

.arrival-Photo{
  /* width : 650px ;
  height : 400px  !important;
  transition: transform 2s, filter 1.5s ease-in-out; */


  width : 600px;
    height:500px;    
    transition: all 2s  ease-in-out;

  

}
.arrival-Photo1{
  /* max-width : 650px !important; */
  height : 400px !important;
  transition: transform 2s, filter 1.5s ease-in-out;
  /* overflow: hidden; */
  max-width : 367px !important;
}
.arrival1{
  overflow: hidden;
  max-width : 367px !important;
  /* padding-left : 40px; */
  /* image-resolution: inherit; */
  height : 400px !important;
  margin: 20px 10px !important;

}

.arrival-Photo11{
  /* transform-origin: center center; */
  /* filter: brightness(100%); */
  /* margin: 20px 10px !important; */
  max-width : 367px !important;
  /* padding-left : 40px; */
  height : 400px !important;
  transition: transform 2s, filter 1.5s ease-in-out;
  overflow: hidden;
}

.arrival-Photo:hover{
  /* filter: brightness(90%); */
  transform: scale(1.1);

}
.arrival-Photo1:hover{
  /* filter: brightness(100%); */
  transform: scale(1.1);
}
.arrival-Photo11:hover{
  /* filter: brightness(100%); */
  transform: scale(1.1);
}

@media screen and (max-width: 1199px) {
  /* .arrival-Photo{ */
    /* margin : 0px auto; */
    /* margin-left : 30px; */
    /* width : 70% !important; */
    /* overflow: auto; */
    /* transform: scale(1.4); */

  /* } */
  .arrival-Photo1{
    margin : 0px auto;
    margin-top : 40px;
    /* margin-left : 30px; */
    width : 1010px !important;
    /* transform: scale(1.4); */

  }
}
@media screen and (max-width: 800px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 550px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 550px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 600px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 470px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 470px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 520px) {
  /* .arrival-Photo{ */
    /* margin : 0px 10px; */
    /* margin-left : 30px; */
    /* width : 400px !important; */
    /* transform: scale(1.4); */
    
  /* } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    width : 400px !important; */
    /* margin-left : 30px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
}
@media screen and (max-width: 450px) {
  /* .arrival-Photo{
    margin : 0px 10px;
    margin-left : 10px;
    max-width : 310px !important;
    padding-left : 20px;

    
  } */
  /* .arrival-Photo1{
    margin : 0px 10px;
    margin-top : 40px;
    max-width : 310px !important;
    padding-left : 20px; */
    /* width : 1010px !important; */
    /* transform: scale(1.4); */

  /* } */
  /* .arrival1{
    margin: auto !important;
    width : 80%;
  } */
  .arrival1{
    max-width : 300px !important;
    height : 400px !important;
  margin  : auto;
  }
.arrival-Photo11{
  max-width : 300px !important;
  margin  : auto;

  /* transform-origin: center center; */
  /* filter: brightness(100%); */
  /* margin: 20px auto !important;
  max-width : 270px !important;
  padding-left : 10px;
  height : 420px !important;
  padding-bottom: 20px !important;
  transition: transform 2s, filter 1.5s ease-in-out; */
}
}
@media screen and (max-width: 310px) {
  
  .arrival1{
    max-width : 250px !important;
    height : 400px !important;
  margin  : auto;
  }
.arrival-Photo11{
  max-width : 250px !important;
  margin  : auto;

}
}
/* .backk{
  background: url("assets/images/back.jpg");

} */
.HeaderMainImp{
  padding-bottom: 20px; 
  /* background: url("assets/images/header-bg-rpt.jpg"); */
  background: white;
  color :  white;
  background-repeat: repeat-x;
  

}
.HeaderMainImp > p{
  margin: 0px 30px; 
  padding: 10px 0px;
}
.mobile-head-menu{
    height : auto ; 
    width:  100% ;
    background-color : rgb(255, 255, 255) !important;
    z-index : -1;
    margin : 0px;
    padding : 0px;
    /* border-bottom: 3px solid black; */


}
.mobile-head-menu > li{
    list-style-type: none;
    padding: 18px 30px;
    border-bottom: 5px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgb(114, 103, 103);
}
.mobile-nav-border {
  font-size: 23px !important;
    /* border-bottom: 3px solid black; */
    /* width : 150px !important; */
    padding:0px 40px 6px 0px !important;
    color : black !important;
    font-weight: 500 !important;
}
.part2-nav{
  padding-bottom: 0px !important;
}
.nested-drop-nav{
  margin : 0px 0px 0px 20px;
  /* color : black !important; */
  font-weight: 500 !important;
  /* padding  : */
}
.nested-drop-nav > li{
  list-style-type: none;
  padding : 15px;
}

.mobile-site-hide-show{
  /* visibility: hidden; */
  background:rgb(245, 245, 245) !important;
  display: none;
}

.desktop-nav{
  position:absolute;
  width : 30%;
  z-index: 99999;
  margin-left : 30%;
  background-color : rgb(207, 248, 152) !important;
  /* display: none; */
}
.desktop-nav-drop{
  height : auto ; 
   width: 40%;
}
.desktop-nav-drop > li{
  list-style-type: none;
  padding : 20px 10px ;
  font-size: 22px !important;
  font-weight: bold;
}
.desktop-nav-drop > li > Link{
  border-bottom: 1px solid #167bff;
}
.site-menu  > li{
  transition: transform 2s, filter 1.5s ease-in-out !important;
  transform-origin: center center;

}
.site-menu > li:hover{
  background-color: rgb(233, 184, 184);
}
.nav-menu-hover-effect:hover .desktop-nav{
  display:none ;
}
/* .desktop-nav{
  display: none ;
}
.desktop-nav:hover{
  display: block ;
} */

#desktop-nav1{
  display: none ;
}
#desktop-nav1:hover{
  display: block ;
}
.nav-menu-hover-effect:hover{
  background-color: #737b8a;
}



 
.logo-design{
  padding-right:50px !important;

}

.input-search-mobile{

  /* margin-left: 30px !important; */
}




@media screen and (max-width: 1200px) {
  
 
  /* .nav-icon-mobile{
    display: none;
    visibility: hidden;
  } */
    .logo-design{
    /* padding-right:150px !important; */
    margin-top:-30px !important ;
    margin-left:-40px !important ;
  
  }
  }

@media screen and (max-width: 800px) {
  

  .input-search-mobile{
    margin-top:  80px !important;
  }
  .logo-design{
    margin-left:0px !important ;
  
  }
  }
  
@media screen and (max-width: 520px) {
  
.logo-design{
  /* padding-right:150px !important; */
  padding-left:0px !important ;
  padding-right: 0px !important;


}
.input-search-mobile{
  margin-left:30px !important ;
  padding : 7px 10px;
  /* margin-top: 10px !important; */
}
.nav-icon-mobile{
  margin-top: -35px !important;
}
.input-search-mobile{
  margin: auto !important;
  margin-top:  40px !important;
}
}

@media screen and (max-width: 820px) {
.site-navigation{
  display: none;
}
/* .mobile-site-hide-show{
  display:  block;
} */
.change-header-color33{
  display:  block;
  height : auto !important;
  font-size: 29px !important;
}
}

@media screen and (min-width: 820px) {

.mobile-site-hide-show{
  display:  none;
}
#mobile-site-hide-show11{
  display:  none !important;
}

}






.shoes-type-Photo11{
  padding-left: 30px;
  width: 700px;
  height : 300px;
}


@media screen and (max-width: 1220px) {

  .shoes-type-Photo11{
    padding-left: 30px;
    width: 450px;
    height : 300px;
  }
}


/* @media screen and () { */

 
/* } */
.change-padding{
  padding : 8px 0px !important
}
@media only screen and (max-width: 1400px) and (min-width: 1230px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 600px;
    height : 300px;
  }
}

@media only screen and (max-width: 1220px) and (min-width: 900px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 450px;
    height : 300px;
  }
}

@media only screen and (max-width: 899px) and (min-width: 680px) {
 .shoes-type-Photo11{
    padding-left: 30px;
    width: 330px;
    height : 200px;
  }
}
@media only screen and (max-width: 681px) and (min-width: 581px) {
 .shoes-type-Photo11{
    padding-left: 0px !important;
    width: 280px;
    height : 180px;
  }
}

@media only screen and (max-width: 580px) and (min-width: 480px) {
 .shoes-type-Photo11{
    padding-left: 70px !important;
    padding-top  : 30px;
    width: 400px;
    height : 180px;
  }
}

@media only screen and (max-width: 479px) and (min-width: 390px) {
 .shoes-type-Photo11{
    padding-left: 35px !important;
    padding-top  : 30px;
    width: 330px;
    height : 180px;
  }
}

@media only screen and (max-width: 389px) and (min-width: 250px) {
 .shoes-type-Photo11{
    /* padding-left: 35px !important; */
    padding-top  : 30px;
    width: 300px;
    height : 180px;
  }
}
@media only screen and (max-width: 189px) and (min-width: 300px) {
 
.pop-up-1{
  /* background : black ;
  color : white ;
  width : 500px;
  padding : 5px 10px ;
  border-radius : 10px ;
  margin :  5px ;
  position : fixed ; */
  /* top : 220% !important; */
  /* left : 32% ;
  z-index : 99999 ;
  visibility: hidden;
  opacity: 0.4; */
}
}


.Shoes-Size-Button {
  max-height : 150px;
  overflow-y: scroll;
}


.Shoes-Size-Button > button{
  margin : 4px 3px;
  padding : px 4px;
  border : 1px solid grey ; 
  background:  transparent;

}


.Shoes-Size-Button1 > button{
  margin : 4px 3px;
  padding : px 4px;
  border : 2px solid grey ; 
  background:  transparent;

}




.details-img-size{
  height:140px !important;
  padding-left: 20px !important;
  width : 25%  !important;
  object-fit: contain;
  /* background-size: cover;
  background-position: center;
  background-image: none; */
}

.container-photo-detail{
  width: 100%;
  /* width : 500px; */
  height:500px;    
  overflow: hidden;
  /* margin: 0 auto; */

}
#changes-Photo{
    width : 500px;
    height:500px;    
    /* transition: all 2s  ease-in-out; */
    transition: 0.5s all ease-in-out;

    /* background-size: cover;
    background-position: center;
    background-image: none;
    transform-origin: center center; */
    /* filter: brightness(100%); */
    /* transition: transform 2s, filter 1.5s ease-in-out; */
}
.carrouselimg1:hover .img-cov{
  transform: scale(1.5);
}
.carrouselimg2:hover .img-cov{
  transform: scale(1.5);
}
.carrouselimg3:hover .img-cov{
  transform: scale(1.5);
}
.carrouselimg4:hover .img-cov{
  transform: scale(1.5);
}

/* #/: scale(1.5); */
  /* width :  700px !important;
  height  : 700px; */
  /* max-width : 500px; */
  /* filter: brightness(90%); */
  /* transform: scale(1.4); */
    /* max-width : 500px; */
    /* transform: translate(50%); */

/* } */

@media only screen and (max-width: 469px) and (min-width: 321px) {
  .container-photo-detail{
    width : 100%;
    /* width : 330px !important; */
    height:400px;    
    overflow: hidden;
    /* margin: 0 auto; */
  
  }
  #changes-Photo{
    width : 100% !important;
    /* width : 330px !important; */
    height:310px !important;    
    /* height:400px !important;     */

}

}

@media only screen and (max-width: 320px) and (min-width: 150px) {
  .container-photo-detail{
    width : 300px !important;
    height:400px;    
    overflow: hidden;
    /* margin: 0 auto; */
  
  }
  #changes-Photo{
    width : 300px !important;
    height:400px !important;    

}

}

.product-detail-descr-rating {
    padding  : 2px;
    border-radius: 30px;
}
.product-detail-descr-rating > div{
    background-color: rgba(0, 0, 0, 0.178);
    /* max-width : 100px !important; */
    padding  : 20px 30px;
}
.product-detail-descr-rating > div > p{
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
}

.product-detail-descr-rating1 {
  margin : 0px;
  padding  : 2px;
  border-radius: 30px;
}
.product-detail-descr-rating1 > div{
  border-radius: 10px;
  border : 2px solid grey;
  border-top: none;
  height : auto !important;
  /* background-color: rgba(0, 0, 0, 0.178); */
  /* max-width : 100px !important; */
  padding  : 20px 30px;
}


.product-detail-descr-rating3 {
  margin : 0px;
  padding  : 2px;
  border : 2px solid grey;
  border-top: none;
  border-radius: 30px;
  color  : black !important;
  font-weight: bold !important;
}

.product-detail-descr-rating3  > div > h3{
  margin-top : -15px;
  /* padding: 0px; */
}


.Icon-start{
  color : rgba(192, 186, 186, 0.432) ;
}
.a1-start:hover{
  color : blue !important;
}
.a2-start:hover{
  color : rgb(231, 255, 145) !important;
}
.a3-start:hover{
  color : rgb(39, 169, 230) !important;
}
.a4-start:hover{
  color : rgb(170, 63, 63) !important;
}
.a5-start:hover{
  color : rgb(14, 167, 35) !important;
}
.root{
  width : 100% !important;
}

.display-none-icon{
  display: none;
}


/* @media only screen and (max-width: 200px) and (min-width: 850px) {
  .display-none-icon{
    display: none;
  }
} */


@media only screen and (max-width: 200px) and (min-width: 999px) {
  .nav-icon-mobile{
    padding-top : 50px !important; 
}
   .change-header-color{
    position: absolute;
    right  : 10%
   }
   .change-header-color1{
    position: absolute;
    right   : 15%
   }
   .change-header-color33{
    position: absolute !important;
    left  : 8%
   }
}

.change-margin1{
  display: none !important;
}
@media  only screen and (min-width: 526px) and (max-width: 800px){
.input-search-mobile {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    /* margin-top: 80px !important; */
}
.disable-nav{
  display: none !important;
}
.change-margin{
  padding: 20px 30px !important;
  /* display: block; */
}
.change-margin1{
  /* padding: 10px 30px !important; */
  display: block !important;
}
}
@media  only screen and (min-width: 200px) and (max-width: 525px){
  .chagne-design-none{
    display: block  !important;
  }
  .image-size-change{
    width : 90%;
  }
  .change-margin1{
    /* padding: 10px 30px !important; */
    display: block !important;
  }
  .disable-nav{
    display: none !important;
  }
  .change-margin{
    padding: 20px 30px !important;
  }
.site-block-top-search .icon {
    right: -3%;
    top: 23% !important;
     }
     .site-block-top-search input{
       padding-left: 0px !important;
     }
     .wsdsd {
      padding-top: 0px !important;
      margin-left: 14px !important;
     }
.site-navbar .site-navbar-top {
      height: 150px;
     }
  .input-search-mobile {
    margin-top: 15px !important;
    margin-bottom: 50px !important;
    /* margin-left: 14px !important; */
    
    /* margin-top: 80px !important; */
}
}

#view-at-hover{
  background-color: rgb(86, 97, 102) !important;
  visibility: hidden;
  transition: .2s all ease-in-out;
}
.view-btn-detail{
  padding : 10px 30px !important;
}

.hover-at-view{
  transition: .2s all ease-in-out;
  visibility: visible !important;
  
}
.hover-at-view:hover{
  background-color: skyblue !important;
}

.hover-product-card:hover{
  /* border: 1px solid grey; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;  
}

.nav-icon-mobile45{
  padding-top: 25px !important;
  padding-left: 30px;
}

.dispaly-bllock-none{
  display: none;
}
@media  only screen and (min-width: 200px) and (max-width: 791px){
  .dispaly-bllock-none{
    display: block !important;
  }

.display-noen{
  display: none !important;
  
}

}
@media  only screen and (min-width: 600px) and (max-width: 991px){
  .fglgiklkkllk{
    display: block !important;
  }
  .fglgiklkkllk1{
    display: block !important;
  }
  
}
@media  only screen and (min-width: 600px) and (max-width: 821px){
  .logo-img{
    margin-left: 91px;
  }
  .fglgiklkkllk1{
    display: none !important;
  }
  .fglgiklkkllk2{
    display: block !important;
  }

}
@media  only screen and (min-width: 500px) and (max-width: 768px){
  .logo-img{
    margin-left: 50% !important;
    width : 235px !important
  }
  .dispaly-bllock-none {
    position: absolute !important;
    right: -3% !important;
  }

}
/* @media  only screen and (min-width: 600px) and (max-width: 991px){
  .fglgiklkkllk{
    display: block !important;
  }
  .fglgiklkkllk1{
    display: block !important;
  }

} */
@media  only screen and (min-width: 700px) and (max-width: 885px){
  .nav-icon-mobile45{
   padding-left: 0px !important;
  }

}
/* @media  only screen and (min-width: 717px) and (max-width: 991px){
  .logo-img{
    margin-left: 191px;
  }
 

} */

@media  only screen and (min-width: 586px) and (max-width: 716px){
 .logo-img{
      margin-left: 81px;
    width : 400px;
 }
 .nav-icon-mobile45{
  padding-left: 40px !important;
}

}

@media  only screen and (min-width: 400px) and (max-width: 585px){
 .logo-img{
      margin-left: 40px !important;
      width : 400px;
 }
 .nav-icon-mobile45{
  padding-left: 44px !important;
      padding-right: 0px !important;

}
.change-header-color33{
  padding-left: 20px;
}
.dispaly-bllock-none{
  margin-left: 40px;
}
}
@media  only screen and (min-width: 200px) and (max-width: 385px){
  .logo-img{
       margin-left: 37px;
       width : 240px;
  }
 }
@media (min-width: 576px){
.col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
}

@media  only screen and (min-width: 400px) and (max-width: 579px){
  .change-header-color33{
   font-size: 49px !important;
 }
}
@media  only screen and (min-width: 330px) and (max-width: 576px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 300px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   width : 310px !important;
 }
 .cart-width-change{
  width : 150px !important;
  position: absolute;
  right: -3%;
 }
 .input-width-change{
  /* max-width :459px !important; */
  margin : auto;
 }
}

@media  only screen and (min-width: 330px) and (max-width: 400px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 270px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   width : 310px !important;
 }
 .cart-width-change{
  width : 150px !important;
  position: absolute;
  right: -3%;
 }
 .input-width-change{
  max-width :329px !important;
  margin : auto;
 }
}

@media  only screen and (min-width: 230px) and (max-width: 329px){
 
.arrival-Photo{
  margin: auto;
    width : 450px;
    height:400px;    
}
  .logo-menu-width-change{
  
    width : 250px !important;
  }
  .arrival{
    
    width : 300px !important;
    height: 300px !important;
 }
 .logo-img{
   padding-left: 40px !important;
   width : 230px !important;
 }
 .cart-width-change{
  width: 70px !important;
  position: absolute;
  right: 19%;
 }
 .input-width-change{
  width :309px !important;
 }
}

.border-0{
  padding-top: 20px !important;
}
.icon-search2{
   padding-top: 10px !important;
 }
 .logo-img {
  width: 163px;
    height: 67px;
}
.site-navbar .site-navbar-top {
  background: white;
  /* background: rgb(97 , 171 ,235); */
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.HeaderMainImp {
  height : 32px;
    padding-bottom: 0px;
    width: 100% !important;
}
.site-footer {
  padding: 0px;
}

#myDiv1234{
  display : none;
  width: 80% !important;
  height : 80% ;
  background-color: white;

}
#mainphoto{
  display : none;
  width: 100% !important;
  height : 100% ;
  background-color: rgb(190, 190, 190);

}
#myDiv23456{
  width: 70% !important;
  height : 80% ;
  position: absolute;
  right: 16%;
  top : 60%;
  z-index: 3;
}
#mainphoto2{
  width: 90% !important;
  height : 90% ;
  position: fixed;
  left: 3%;
  top : 5%;
  z-index: 5;
}
#photo33{
  /* right: 5%; */
  /* top : 180% !important; */
  position: fixed !important;
  z-index: 6;
  right: 2% !important;
  
  top: 7% !important;
}
@media  only screen and (min-width: 530px) and (max-width: 1029px){
  .div-size-respon{
    border-radius: 20px !important;
  }
  #myDiv23456{
    right: 25%;
    top : 180% !important;
    z-index: 5;
    height : 70% ;

  }
  #mainphoto2{
    /* right: 25%; */
    top : 5% !important;
    z-index: 3;
    /* height : 70% ; */

  }
  #myDiv34567{
    right: 25%;
    top : 1% !important;
    z-index: 3;
  }
  #photo33{
    /* right: 5%; */
    /* top : 180% !important; */
    /* z-index: 3; */
    position: fixed;
  /* z-index: 4; */
  right: 2%;
  top: 8%;
  }
}

@media  only screen and (min-width: 170px) and (max-width: 529px){
  .change-texts{
    margin-top:  24px !important;
  }
  .div-size-respon{
    width: 90% !important;
  }
  #myDiv23456{
    right: 20%;
    top : 150% !important;
    z-index: 3;
    height: 60%;
    width : 90%;
  }
  #mainphoto2{
    /* right: 20%; */
    top : 10% !important;
    z-index: 3;

    left : 6% !important;
    width : 85% !important;
    height : 85% !important;
  }
  #myDiv34567{
    right: 25%;
    top : 1% !important;
    z-index: 3;
    /* width : 90% !important; */

  }
  #photo33{
    position: fixed;
    /* z-index: 4; */
    right: .5%;
    top: 9% !important;

  }

  /* #myDiv23456{
    right: 25%;
    top : 180% !important;
    z-index: 3;
    height : 70% ;

  }
  #myDiv34567{
    right: 25%;
    top : 180% !important;
    z-index: 3;
  } */
  /* #myDiv23456 {
    width: 82% !important;
    height: 80%;
    position: absolute;
    right: 16%;
    top: 60%;
    z-index: 3;
}
  #myDiv1234{
    width: 90% !important;
  
  } */
  /* #myDiv23456{
    right: 3%;
    top : 180% !important;
    z-index: 3;
    height : 90% ;

  } */
  /* #myDiv34567{
    right: 3%;
    top : 180% !important;
    z-index: 3;
  } */
}
#myDivUmer{
  padding: 0px 0px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  /* top : 30%; */
  display : none;
  width: 46% !important;
  height : 49% ;
  background-color: white;
  /* margin-left : 30%; */
  border: 4px solid grey;
  bottom: 0px;
  right: 8%;

}
@media  only screen and (min-width: 530px) and (max-width: 1029px){
 
  #myDivUmer{
    height:320px;
    width: 200px;
    margin-left : 165px;
  }
}

@media  only screen and (min-width: 170px) and (max-width: 529px){
 
  #myDivUmer{
    height:300px !important;
    width: 300px !important;
    margin-left : 11%;
  }
}
@media  only screen and (min-width: 320px) and (max-width: 390px){
  .logo-img{
       margin-left: 38px;
       width : 234px !important;
  }
  .nav-icon-mobile45{
   /* padding-left: 70px !important;
       padding-right: 0px !important; */
    right: 13px;
    position: absolute;
    padding-top: 28px !important;
 }
}


@media  only screen and (min-width: 820px) and (max-width: 129px){
  .change-header-color33{
    display : block !important;
  }
 
  [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
  
.display-6{
  display: none;
}
  @media  only screen and (min-width: 120px) and (max-width: 429px){
    .dispaly-block{
      display: none;
    }
    .display-6{
      display: block;
    }
   .HeaderMainImp {
      width: 103% !important;
  }
  }
  @media  only screen and (min-width: 120px) and (max-width: 629px){
   
    .stext-104{
      width: 100% !important;
    }
  }




  .loader-1{
    position : fixed  ;
    left: 45%;
    top:20%;
    z-index: 9999;
  }


  @media  only screen and (min-width: 120px) and (max-width: 529px){
  .loader-1{
    position : fixed  ;
    left: 35%;
    top:20%;
    z-index: 9999;
  }
  .rgjrgou{
    width: 95% !important;
    margin: 10px 1px 50px 10px !important;
    padding: 29px 21px !important;
    box-shadow: 0px 2px 6px red;
  }
  .rgjrgou1{
    width: 90% !important;
    margin: 10px 0px 50px 19px !important;
    padding: 29px 21px !important;
    box-shadow: 0px 2px 6px red;
  }
  .site-navbar {
    width: 97% !important;
}
  }
  .ci11{
    visibility: hidden;
  }





.adjustTheSubmitButton{
    /* position: absolute;
    right : 147px;
    bottom:  7px; */
    /* background-color: #343443;  */
    border: none;
    color: black;
    padding: 1px 10px;
    border-radius:  40px;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 16px;}



    .messageStyle{
      background: skyblue;
      display: inline-block;
      padding: 10px 15px !important;
      border-radius: 10px;
      margin: 15px !important;
      overflow-wrap:anywhere;
      min-width: 40px !important;
      max-width: 550px !important;
  
  }
  /* .chatControls{
    display: flex;
    position: fixed;
    width: 34%;
    height: 50px;
    bottom: 0;
}
.chatControls div{
    padding-left: 30px  !important;
    border:2px solid #000;
    border-radius: 10px;
    width: 87%;
}

    .chatControls label{
        position: absolute;
        right : 147px;
        bottom:  -2px;
        background-color: skyblue;
        border: none;
        color: black;
        padding: 5px 10px;
        border-radius:  40px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
    } */
    .wsdsd{
      padding-top: 7px !important;
      margin-left: 30px;
       background-color: transparent !important;
    }
    .rlkgeglktgkl{
      background-color: rgb(254, 189, 105);
      border-bottom-right-radius: 29px;
      border-top-right-radius: 29px;
      width: 48px !important;
      height:44px !important;
      /* margin-left: -15px; */
    margin-top: 5px;
    padding: 6px;
    color : black;
    }
    
.erkrkk:hover .dropdown123 {
	opacity: 1;
	visibility: visible;
	top: 52px;
}
.erkrkk:hover .dropdown1234 {
	opacity: 1;
	visibility: visible;
	top: 52px;
}

.dropdown123 {
  position: absolute;
  left: 10%;
  top: 100% !important;
  width: 80%;
  background: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: black;
}

 .dropdown123 li {
	display: block;
	margin-left: 0;
}

 .dropdown123 li:hover>a {
	background: transparent;
	color: #e7ab3c;
}

 .dropdown123 li a {
	border-right: 0;
	padding: 13px 20px 12px 30px;
	text-transform: capitalize;
}

.dropdown1234 {
  position: absolute;
  left: 40%;
  top: 100% !important;
  width: 30%;
  background: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: black;
  padding: 0px 30px;
}

 .dropdown1234 li {
	display: block;
	margin-left: 0;
}

 .dropdown1234 li:hover>a {
	background: transparent;
	color: #e7ab3c;
}

 .dropdown1234 li a {
	border-right: 0;
	padding: 13px 20px 12px 30px;
	text-transform: capitalize;
}
.fgilklk{
  padding   : 10px 30px;
  transition: all 0.3s;
  cursor: pointer !important;
}
.fgilklk1{
  padding   : 3px 10px;
  /* transition: all 0.3s;
  cursor: pointer !important; */
  border-bottom: 1px solid grey;
}
.elfkefkl{
  padding   : 5px 10px !important;
  font-size: 13px !important;
  font-weight: bold;
  margin :  0px !important;
}
.fgilklk:hover{
  background-color: #d4d4d4;
}
.dffkl{
  display:  none;
}

.rtrtrtt2323{
  display: none;
}
@media  only screen and (min-width: 200px) and (max-width: 525px){
  .jkkjkj{
    width: 58% !important;
}

  .rtrtrtt{
    display: none !important;
  }
  .rtrtrtt2323{
    display: block !important;
  }
  .site-block-top-search .icon {
      right: -3%;
      top: 23% !important;
       }
       .site-block-top-search input{
         padding-left: 0px !important;
       }
       .wsdsd {
        padding-top: 0px !important;
        margin-left: 14px !important;
       }
       .wsduhu{
         display:  none !important;
       }
       .dffkl{
        display:  block !important;
      }
      }

      .dfidfpfdfp{
        padding-left: 30px !important;
      }
      @media only screen and (min-width: 1400px) and (max-width: 1600px) {
        .site-block-top-search .icon{
          right: -1.6% !important;
        }
        .efeff{
          font-size: 17px !important;
        }
        .erfklkl{
          width: 185px !important;
        } 
      }
      @media only screen and (min-width: 900px) and (max-width: 1600px) {
        .jkkukk{
          height: 360px !important;
        }
      }
      @media only screen and (min-width: 1200px) and (max-width: 1399px) {
        .efeff{
          font-size: 17px !important;
        }
        .erfklkl{
          width: 185px !important;
        } 
        .site-block-top-search .icon{
    right: -1.8% !important;
  }
}
@media only screen and (min-width: 1032px) and (max-width: 1199px) {
  .site-block-top-search .icon{
    right: -2.1% !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 991px) {
  .site-block-top-search .icon{
    right: -3.4% !important;
  }
}

#myDiv1234{
  padding: 30px 10px;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  top : 11%;
  display : none;
  width: 21% !important;
  right:8%;
  height : 31% ;
  background-color: white;
  box-shadow: grey 0px 2px 6px;
  /* margin-left : 30%; */
  /* border: 4px solid grey; */


}
@media  only screen and (min-width: 530px) and (max-width: 1029px){
  #myDiv1234{
    height:320px;
    width: 200px;
    right: 9%;
    /* margin-left : 165px; */
  }}

  
@media  only screen and (min-width: 170px) and (max-width: 529px){
  
  #myDiv1234{
    height:268px !important;
    width: 300px !important;
    margin-left : 11%;
  }}


  .fjfklflkdkld{
    padding: 10px 30px;
    background: red;
    color: white;
    border: transparent;
    border-radius: 7px;
    width: 90%;
    margin : 5px 0px
  }
  .ul-li-signup > li{
   list-style: none;
  }
  .ul-li-signup >  li{
    font-size: 16px ;
    font-weight: bold;
    padding:  10px 0px;
  }
  .ul-li-signup1 > li{
   list-style: none;
  }
  .ul-li-signup1 >  li{
    font-size: 16px ;
    font-weight: bold;
    padding:  8px 0px;
  }

  /*------------------------------------------------------------------
    IMPORT FONTS
-------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Dosis:200,300,400,500,600,700');


/*

    font-family: 'Poppins', sans-serif;

    font-family: 'Dosis', sans-serif;

*/

/*------------------------------------------------------------------
    IMPORT FILES
-------------------------------------------------------------------*/

/* @import url("assets/css/all.css");
@import url(assets/css/superslides.css);
@import url(assets/css/bootstrap-select.css);
@import url(assets/css/carousel-ticker.css);
@import url(assets/css/code_animate.css);
@import url(assets/css/bootsnav.css);
@import url(assets/css/owl.carousel.min.css);
@import url(assets/css/jquery-ui.css); */
/* @import url("assets/css/nice-select.css"); */
/* @import url(assets/css/baguetteBox.min.css); */

/*------------------------------------------------------------------
    SKELETON
-------------------------------------------------------------------*/
 body {
     color: #666666;
     font-size: 15px;
     font-family: 'Dosis', sans-serif;
     line-height: 1.80857;
}
 a {
     color: #1f1f1f;
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}
 h1, h2, h3, h4, h5, h6 {
     letter-spacing: 0;
     font-weight: normal;
     position: relative;
     padding: 0 0 10px 0;
     font-weight: normal;
     line-height: 120% !important;
     color: #1f1f1f;
     margin: 0 
}
 h1 {
     font-size: 24px 
}
 h2 {
     font-size: 22px 
}
 h3 {
     font-size: 18px 
}
 h4 {
     font-size: 16px 
}
 h5 {
     font-size: 14px 
}
 h6 {
     font-size: 13px 
}
 h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
     color: #212121;
     text-decoration: none!important;
     opacity: 1 
}
 h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
     opacity: .8 
}
 a {
     color: #1f1f1f;
     text-decoration: none;
     outline: none;
}
 a, .btn {
     text-decoration: none !important;
     outline: none !important;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     transition: all .3s ease-in-out;
}
 .btn-custom {
     margin-top: 20px;
     background-color: transparent !important;
     border: 2px solid #ddd;
     padding: 12px 40px;
     font-size: 16px;
}
 .lead {
     font-size: 18px;
     line-height: 30px;
     color: #767676;
     margin: 0;
     padding: 0;
}
 blockquote {
     margin: 20px 0 20px;
     padding: 30px;
}
 ul, li, ol{
     list-style: none;
     margin: 0px;
     padding: 0px;
}
 button:focus{
     outline: none;
     box-shadow: none;
}
 :focus {
     outline: 0;
}
 p{
     margin: 0px;
}
 .bootstrap-select .dropdown-toggle:focus{
     outline: none !important;
}
 .form-control::-moz-placeholder {
     color: #ffffff;
     opacity: 1;
}
 .bootstrap-select .dropdown-toggle:focus{
     box-shadow: none !important 
}
/*------------------------------------------------------------------ LOADER -------------------------------------------------------------------*/
 #back-to-top {
     position: fixed;
     bottom: 90px;
     right: 30px;
     z-index: 9999;
     width: 32px;
     height: 32px;
     text-align: center;
     line-height: 24px;
     background: rgb(78 90 85);
     color: #ffffff;
     cursor: pointer;
     border: 0;
     border-radius: 0px;
     text-decoration: none;
     transition: opacity 0.2s ease-out;
     font-size: 20px;
}
/*------------------------------------------------------------------ HEADER -------------------------------------------------------------------*/
 .main-top{
     background:rgb(78 90 85);
     padding: 10px 0px;
}
 .custom-select-box{
     float: left;
     width: 95px;
     margin-right: 10px;
}
.custom-select-box .form-control{
     background: none;
     border: none;
}
 .custom-select-box .bootstrap-select .btn-light{
     padding: 4px;
     font-size: 14px;
     background: rgb(78 90 85);
     color: #fff;
     border: none;
     border-radius: 0px;
}
 .custom-select-box .bootstrap-select .btn-light span{
     padding: 2px;
     line-height: 15px;
}
 .dropdown-toggle::after{
     margin-left: -24px;
}
 .custom-select-box .dropdown-toggle::after {
     display: inline-block;
     width: 0;
     height: 0;
     margin-left: .255em;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid;
     border-right: .3em solid transparent;
     border-bottom: 0;
     border-left: .3em solid transparent;
     position: absolute;
     top: 13px;
     right: 10px;
}
 .bootstrap-select.btn-group .dropdown-toggle .filter-option{
     overflow: inherit;
}
 .bootstrap-select.btn-group .dropdown-menu{
     border: none;
     padding: 0px;
     border-radius: 0px;
}
 .right-phone-box{
     float: left;
     margin-right: 10px;
}
 .right-phone-box p{
     margin: 0px;
     color: #ffffff;
     font-size: 14px;
     line-height: 30px;
}
 .right-phone-box p a{
     color: #ffffff;
}
 .right-phone-box p a:hover{
     color: rgb(78 90 85);
}
 .offer-box,.slide {
     color: #FFFFFF;
     font-size: 13px;
     padding: 2px 15px;
     font-family: 'Poppins', sans-serif;
}
 .offer-box li {
     font-weight: 600;
}
 .offer-box li i{
     margin-right: 15px;
     color: rgb(78 90 85);
     font-size: 20px;
}
 .our-link{
     float: left;
}
 .our-link ul li{
     display: inline-block;
     border-right: 1px solid #ffffff;
     padding: 0px 10px;
     line-height: 14px;
}
 .our-link ul{
     line-height: 30px;
}
 .our-link ul li a{
     color: #ffffff;
     font-weight: 700;
     text-transform: uppercase;
     font-size: 14px;
}
 .our-link ul li a:hover{
     color: rgb(78 90 85);
}
 .our-link ul li:last-child{
     border: none;
}
.login-box{
    float: right;
    width: 120px;
    margin-left: 20px;
}

.login-box .form-control{
     background: none;
     border: none;
}
 .login-box .bootstrap-select .btn-light{
     padding: 4px;
     font-size: 14px;
     background: rgb(78 90 85);
     color: #fff;
     border: none;
     border-radius: 0px;
}
 .login-box .bootstrap-select .btn-light span{
     padding: 2px;
     line-height: 15px;
}
.login-box .dropdown-toggle::after {
     display: inline-block;
     width: 0;
     height: 0;
     margin-left: .255em;
     vertical-align: .255em;
     content: "";
     border-top: .3em solid;
     border-right: .3em solid transparent;
     border-bottom: 0;
     border-left: .3em solid transparent;
     position: absolute;
     top: 13px;
     right: 10px;
}

.login-box .dropdown-item{
	padding: 5px 10px;
}

.bootstrap-select.btn-group .dropdown-menu a.dropdown-item span.dropdown-item-inner span.text{
	font-size: 13px;
}

 .main-header{
}
 .search a{
     color: rgb(78 90 85);
}
 .attr-nav > ul > li > a:hover{
     color: rgb(78 90 85);
}
 nav.navbar.bootsnav ul.nav > li > a{
     margin: 0px;
}
/* Navbar Adjusment =========================== */
/* Navbar Atribute ------*/
 .attr-nav > ul > li > a{
     padding: 28px 15px;
}
 ul.cart-list > li.total > .btn{
     border-bottom: solid 1px #cfcfcf !important;
     color: #000000 ;
     padding: 10px 15px;
     border: none;
     font-weight: 700;
     color: #ffffff;
}
 @media (min-width: 1024px) {
    /* Navbar General ------*/
     nav.navbar ul.nav > li > a{
         padding: 30px 15px;
         font-weight: 600;
    }
     nav.navbar .navbar-brand{
         margin-top: 0;
    }
     nav.navbar .navbar-brand{
         margin-top: 0;
    }
     nav.navbar li.dropdown ul.dropdown-menu{
         border-top: solid 5px;
    }
    /* Navbar Center ------*/
     nav.navbar-center .navbar-brand{
         margin: 0 !important;
    }
    /* Navbar Brand Top ------*/
     nav.navbar-brand-top .navbar-brand{
         margin: 10px !important;
    }
    /* Navbar Full ------*/
     nav.navbar-full .navbar-brand{
         position: relative;
         top: -15px;
    }
    /* Navbar Sidebar ------*/
     nav.navbar-sidebar ul.nav, nav.navbar-sidebar .navbar-brand{
         margin-bottom: 50px;
    }
     nav.navbar-sidebar ul.nav > li > a{
         padding: 10px 15px;
         font-weight: bold;
    }
    /* Navbar Transparent & Fixed ------*/
     nav.navbar.bootsnav.navbar-transparent.white{
         background-color: rgba(255,255,255,0.3);
         border-bottom: solid 1px #bbb;
    }
     nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark, nav.navbar.bootsnav.navbar-transparent.dark{
         background-color: rgba(0,0,0,0.3);
         border-bottom: solid 1px #555;
    }
     nav.navbar.bootsnav.navbar-transparent.white .attr-nav{
         border-left: solid 1px #bbb;
    }
     nav.navbar.navbar-inverse.bootsnav.navbar-transparent.dark .attr-nav, nav.navbar.bootsnav.navbar-transparent.dark .attr-nav{
         border-left: solid 1px #555;
    }
     nav.navbar.bootsnav.no-background.white .attr-nav > ul > li > a, nav.navbar.bootsnav.navbar-transparent.white .attr-nav > ul > li > a, nav.navbar.bootsnav.navbar-transparent.white ul.nav > li > a, nav.navbar.bootsnav.no-background.white ul.nav > li > a{
         color: #fff;
    }
     nav.navbar.bootsnav.navbar-transparent.dark .attr-nav > ul > li > a, nav.navbar.bootsnav.navbar-transparent.dark ul.nav > li > a{
         color: #eee;
    }
}
 @media (max-width: 992px) {
    /* Navbar General ------*/
     nav.navbar .navbar-brand{
         margin-top: 0;
         position: relative;
         top: -2px;
    }
     nav.navbar .navbar-brand img.logo{
      /* width: 115px !important;
      height: 69px;
      object-fit: unset; */
      width: 109px!important;
    height: 52px !important;
    object-fit: fill;
    margin-left: -20px;
    }
     .attr-nav > ul > li > a{
         padding: 16px 15px 15px;
    }
    /* Navbar Mobile slide ------*/
     nav.navbar.navbar-mobile ul.nav > li > a{
         padding: 15px 15px;
    }
     nav.navbar.navbar-mobile ul.nav ul.dropdown-menu > li > a{
         padding-right: 15px !important;
         padding-top: 15px !important;
         padding-bottom: 15px !important;
    }
     nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu .title{
         padding-right: 30px !important;
         padding-top: 13px !important;
         padding-bottom: 13px !important;
    }
     nav.navbar.navbar-mobile ul.nav ul.dropdown-menu .col-menu ul.menu-col li a{
         padding-top: 13px !important;
         padding-bottom: 13px !important;
    }
    /* Navbar Full ------*/
     nav.navbar-full .navbar-brand{
         top: 0;
         padding-top: 10px;
    }
}
/* Navbar Inverse =================================*/
 nav.navbar.navbar-inverse{
     background-color: #222;
     border-bottom: solid 1px #303030;
}
 nav.navbar.navbar-inverse ul.cart-list > li.total > .btn{
     border-bottom: solid 1px #222 !important;
}
 nav.navbar.navbar-inverse ul.cart-list > li.total .pull-right{
     color: #fff;
}
 nav.navbar.navbar-inverse.megamenu ul.dropdown-menu.megamenu-content .content ul.menu-col li a, nav.navbar.navbar-inverse ul.nav > li > a{
     color: #eee;
}
 nav.navbar.navbar-inverse ul.nav > li.dropdown > a{
     background-color: #222;
}
 nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a{
     color: #999;
}
 nav.navbar.navbar-inverse ul.nav .dropdown-menu h1, nav.navbar.navbar-inverse ul.nav .dropdown-menu h2, nav.navbar.navbar-inverse ul.nav .dropdown-menu h3, nav.navbar.navbar-inverse ul.nav .dropdown-menu h4, nav.navbar.navbar-inverse ul.nav .dropdown-menu h5, nav.navbar.navbar-inverse ul.nav .dropdown-menu h6{
     color: #fff;
}
 nav.navbar.navbar-inverse .form-control{
     background-color: #333;
     border-color: #303030;
     color: #fff;
}
 nav.navbar.navbar-inverse .attr-nav > ul > li > a{
     color: #eee;
}
 nav.navbar.navbar-inverse .attr-nav > ul > li.dropdown ul.dropdown-menu{
     background-color: #222;
     border-left: solid 1px #303030;
     border-bottom: solid 1px #303030;
     border-right: solid 1px #303030;
}
 nav.navbar.navbar-inverse ul.cart-list > li{
     border-bottom: solid 1px #303030;
     color: #eee;
}
 nav.navbar.navbar-inverse ul.cart-list > li img{
     border: solid 1px #303030;
}
 nav.navbar.navbar-inverse ul.cart-list > li.total{
     background-color: #333;
}
 nav.navbar.navbar-inverse .share ul > li > a{
     background-color: #555;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-menu{
     border-right: solid 1px #303030;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a{
     border-bottom: solid 1px #303030;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-content{
     border-left: solid 1px #303030;
}
 nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:hover, nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li > a:focus, nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul > li.active > a{
     background-color: #333 !important;
}
 nav.navbar-inverse.navbar-full ul.nav > li > a{
     border:none;
}
 nav.navbar-inverse.navbar-full .navbar-collapse .wrap-full-menu{
     background-color: #222;
}
 nav.navbar-inverse.navbar-full .navbar-toggle{
     background-color: #222 !important;
     color: #6f6f6f;
}
 nav.navbar.bootsnav ul.nav > li > a{
     position: relative;
     font-weight: 700;
     font-size: 15px;
     color: #000000;
	 text-transform: uppercase;
     /* padding-top: 10px;
     padding-bottom: 10px; */
     padding-left: 10px;
     padding-right: 30px;
}
 nav.navbar.bootsnav ul.nav li.active > a{
     color: rgb(78 90 85);
}
 nav.navbar.bootsnav ul.nav li.active > a:hover{
     color: rgb(78 90 85);
}
 nav.navbar.bootsnav ul.nav li > a:hover{
     color: rgb(78 90 85);
}
 nav.navbar.bootsnav ul.nav li.megamenu-fw > a:hover{
     color: rgb(78 90 85);
}
 @media (min-width: 1024px) {
     nav.navbar.navbar-inverse ul.nav .dropdown-menu{
         background-color: #222 !important;
         border-left: solid 1px #303030 !important;
         border-bottom: solid 1px #303030 !important;
         border-right: solid 1px #303030 !important;
    }
     nav.navbar.navbar-inverse li.dropdown ul.dropdown-menu > li > a{
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse ul.dropdown-menu.megamenu-content .col-menu{
         border-left: solid 1px #303030;
         border-right: solid 1px #303030;
    }
     nav.navbar.navbar-inverse.navbar-transparent.dark{
         background-color: rgba(0,0,0,0.3);
         border-bottom: solid 1px #999;
    }
     nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav{
         border-left: solid 1px #999;
    }
     nav.navbar.navbar-inverse.no-background.white .attr-nav > ul > li > a, nav.navbar.navbar-inverse.navbar-transparent.dark .attr-nav > ul > li > a, nav.navbar.navbar-inverse.navbar-transparent.dark ul.nav > li > a, nav.navbar.navbar-inverse.no-background.white ul.nav > li > a{
         color: #fff;
    }
     nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a, nav.navbar.navbar-inverse.no-background.dark .attr-nav > ul > li > a, nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a, nav.navbar.navbar-inverse.no-background.dark ul.nav > li > a{
         color: #3f3f3f;
    }
}
 @media (max-width: 992px) {
     nav.navbar.navbar-inverse .navbar-toggle{
         color: #eee;
         background-color: #222 !important;
    }
     nav.navbar.navbar-inverse .navbar-nav > li > a{
         border-top: solid 1px #303030;
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse ul.nav li.dropdown ul.dropdown-menu > li > a{
         color: #999;
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu .title{
         border-bottom: solid 1px #303030;
         color: #eee;
    }
     nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu ul > li > a{
         border-bottom: solid 1px #303030;
         color: #999 !important;
    }
     nav.navbar.navbar-inverse .dropdown .megamenu-content .col-menu.on:last-child .title{
         border-bottom: solid 1px #303030;
    }
     nav.navbar.navbar-inverse .dropdown-tabs .tab-menu > ul{
         border-top: solid 1px #303030;
    }
     nav.navbar.navbar-inverse.navbar-mobile .navbar-collapse{
         background-color: #222;
    }
}
 @media (max-width: 767px) {
     nav.navbar.navbar-inverse.navbar-mobile ul.nav{
         border-top: solid 1px #222;
    }
}
 .arrow::before{
     font-family: 'FontAwesome';
     content: "\f0d7";
     margin-left: 5px;
     margin-top: 2px;
     border: none;
     display: inline-block;
     vertical-align: inherit;
     position: absolute;
     right: 10px;
     top: 10px;
}
 .dropdown-toggle::after{
     display: none;
}
 nav.navbar.bootsnav ul.navbar-nav li.dropdown ul.dropdown-menu li a{
     display: block;
	 padding: 6px 25px;
}

 nav.navbar.bootsnav ul.navbar-nav li.dropdown ul.dropdown-menu li a:hover{
	 
 }
 nav.navbar.bootsnav li.dropdown ul.dropdown-menu{
     left: auto;
}
.custom-select-box .dropdown-item{
	padding: 5px 10px;
}
 .btn-cart{
}
 .cart-list h6 a{
     font-size: 16px;
     font-weight: 700;
}
 .cart-list h6 a:hover{
     color: rgb(78 90 85);
}
 ul.cart-list p .price{
     font-weight: normal;
}
.side-menu p{
	display: inline-block;
}
 .col-menu .title{
     font-size: 20px;
     font-weight: 700;
     text-transform: uppercase;
}
 .hvr-hover{
     display: inline-block;
     vertical-align: middle;
     -webkit-transform: perspective(1px) translateZ(0);
     transform: perspective(1px) translateZ(0);
     box-shadow: 0 0 1px rgba(0, 0, 0, 0);
     position: relative;
     background: rgb(78 90 85);
     -webkit-transition-property: color;
     transition-property: color;
     -webkit-transition-duration: 0.3s;
     transition-duration: 0.3s;
     border-radius: 0;
     box-shadow: none;
}
 .hvr-hover::after {
    content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #F2AA4CFF;
	border-radius: 100%;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}
 .hvr-hover:hover::after{
     -webkit-transform: scale(2);
	 transform: scale(2);
     color: #ffffff;
}
 .hvr-hover{
	 overflow: hidden;
}
 ul.cart-list > li.total > .btn:hover{
     color: #ffffff;
}
 .cart-box{
     margin-top: 40px;
     background: #ffffff;
}
 .main-header.fixed-menu {
     position: fixed;
     visibility: hidden;
     left: 0px;
     top: 0px;
     width: 100%;
     padding: 0px 0px;
     background: #ffffff;
     z-index: 0;
     transition: all 500ms ease;
     -moz-transition: all 500ms ease;
     -webkit-transition: all 500ms ease;
     -ms-transition: all 500ms ease;
     -o-transition: all 500ms ease;
     z-index: 999;
     opacity: 1;
     visibility: visible;
     -ms-animation-name: fadeInDown;
     -moz-animation-name: fadeInDown;
     -op-animation-name: fadeInDown;
     -webkit-animation-name: fadeInDown;
     animation-name: fadeInDown;
     -ms-animation-duration: 500ms;
     -moz-animation-duration: 500ms;
     -op-animation-duration: 500ms;
     -webkit-animation-duration: 500ms;
     animation-duration: 500ms;
     -ms-animation-timing-function: linear;
     -moz-animation-timing-function: linear;
     -op-animation-timing-function: linear;
     -webkit-animation-timing-function: linear;
     animation-timing-function: linear;
     -ms-animation-iteration-count: 1;
     -moz-animation-iteration-count: 1;
     -op-animation-iteration-count: 1;
     -webkit-animation-iteration-count: 1;
     animation-iteration-count: 1;
}
 .main-header.fixed-menu {
     padding: 0px;
     box-shadow: 0 0 8px 0 rgba(0,0,0,.12);
     border-radius: 0;
}
 .top-search .input-group-addon{
     line-height: 40px;
}
 .top-search input.form-control{
     color: #ffffff;
}
/*------------------------------------------------------------------ Slider -------------------------------------------------------------------*/
 .cover-slides{
     height: 100vh;
}
 .slides-navigation a {
     background: rgb(78 90 85);
     position: absolute;
     height: 70px;
     width: 70px;
     top: 50%;
     font-size: 20px;
     display: block;
     color: #fff;
     line-height: 90px;
     text-align: center;
     transition: all .3s ease-in-out;
}
 .slides-navigation a i{
     font-size: 40px;
}
 .slides-navigation a:hover {
     background: #000000;
}
 .cover-slides .container{
     height: 100%;
     position: relative;
     z-index: 2;
}
 .cover-slides .container > .row {
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
}
 .cover-slides .container > .row {
     height: 100%;
}
 .overlay-background {
     background: #333;
     position: absolute;
     height: 100%;
     width: 100%;
     left: 0;
     top: 0;
     opacity: 0.5;
}
 .cover-slides h1{
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     font-size: 64px;
     color: #fff;
}
 .erekj{
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
     font-size: 64px;
     color: #fff;
}
 .cover-slides p{
     font-size: 18px;
     color: #fff;
     padding-bottom: 30px;
}
 .dsflk{
     font-size: 18px;
     color: #fff;
     padding-bottom: 30px;
}
 .slides-pagination a{
     border: 2px solid #ffffff;
	 border-radius: 6px;
}
 .slides-pagination a.current{
     background: rgb(78 90 85);
     border: 2px solid rgb(78 90 85);
}
 .cover-slides p a{
     font-size: 24px;
     color: #ffffff;
     border: none;
     text-transform: uppercase;
     padding: 10px 20px;
}
 .sdklkl{
     font-size: 24px;
     color: #ffffff;
     border: none;
     text-transform: uppercase;
     padding: 10px 20px;
}
.slide {
  padding: 0px !important;
  max-height: 738px !important;
}
.efeeee{
  height: 700px !important;
  width: inherit;
}
/* .carousel-indicators{
  display: none !important;
} */
/*------------------------------------------------------------------ Categories Shop -------------------------------------------------------------------*/
 .categories-shop{
     padding: 70px 0px;
}
 .shop-cat-box{
     margin-bottom: 30px;
     position: relative;
     padding: 3px;
     overflow: hidden;
     border: 0px solid #000000;
	 box-shadow: 9px 9px 30px 0px rgba(0, 0, 0, 0.3);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease-in-out 0s;
}
 .shop-cat-box img{
     margin: 23px 0 0 -15px;
     max-width: none;
     width: -webkit-calc(100% + 10px);
     width: calc(100% + 10px);
     opacity: 0.9;
     -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
     transition: opacity 0.35s, transform 0.35s;
     -webkit-transform: translate3d(10px,10px,0);
     transform: translate3d(10px,10px,0);
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;
}
 .shop-cat-box:hover img{
     opacity: 0.6;
     -webkit-transform: translate3d(0,0,0);
     transform: translate3d(0,0,0);
}
 .shop-cat-box a{
     position: absolute;
     z-index: 2;
     bottom: 0px;
     left: 0px;
     right: 0;
     margin: 0 auto;
     text-align: center;
     border: none;
     color: #ffffff;
     font-size: 18px;
     font-weight: 700;
     padding: 6px 0px;
}

.box-add-products{
	padding: 70px 0px;
	background-color: #f4f4f4;
}

.offer-box{
	position: relative;
	overflow: hidden;
}

.offer-box-products{
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease-in-out 0s;
}

.offer-box-products:hover{
	-webkit-transform: translateY(6px);
	transform: translateY(6px);
	box-shadow: 0px 9px 15px 0px rgba(0, 0, 0, 0.1);
}
.sub-menu {
  list-style-type: none;
  position: absolute;
  top: 77px;
  left: -58%;
  z-index: 9;
  min-width: 222px;
  max-width: 225px;
  background-color: rgb(0, 0, 0);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  padding: 5px 0;
  box-shadow: 0 1px 5px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 5px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 1px 5px 0px rgb(0 0 0 / 20%);
   visibility: hidden;
 opacity: 0; 
 color:  white;
}
.sub-menu4{
  display:  none;
}
.sub-menu3{
  display:  none;
}
.sub-menu > li > a{

  color:  white !important;
}
.sub-menu1 > li> a {

  color:  white !important;
}
.sub-menu1 a:hover {

  color:  #e7ab3c !important;
}
.sub-menu a:hover {

  color:  #e7ab3c !important;
}
.the456  {
  /* margin-left: 13% !important; */
  font-family: 'Poppins', sans-serif !important;
}
.sub-menu1 {
  color:  white;
  list-style-type: none;
  position: absolute;
  top: 77px;
  left: -207%;
  /* left: -107%; */
  z-index: 9;
  width: 800%;
  /* width: 85vh; */
  background-color: rgb(0, 0, 0);
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  padding: 39px 52px;
  box-shadow: 0 1px 5px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 5px 0px rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 1px 5px 0px rgb(0 0 0 / 20%);
   visibility: hidden;
 opacity: 0; 
}

.changer1:hover > .sub-menu1{
  visibility: visible !important;
  opacity: 1 !important; 

}
.changer:hover > .sub-menu{
  visibility: visible !important;
  opacity: 1 !important; 

}
.sub-menu li {
  position: relative;
  background-color: transparent;

  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.main-menu > li > .sub-menu {
  top:100%;
  left: 0;
}

.sub-menu a {
  font-family: monospace;
  font-size: 16px ;
  font-weight: 600;
  line-height: 1.5;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  display: block;
  padding: 8px 20px; 
  width: 100%;
  
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}
.sub-menu1 a {
  font-family: monospace;
  font-size: 17px;
  line-height: 1.5;
  color: rgb(255, 255, 255);
  text-transform: uppercase;

  display: block;
  padding: 8px 20px; 
  width: 100%;

  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

/*---------------------------------------------*/
.main-menu > li:hover > a {
  text-decoration: none;
  color: #6c7ae0;
}

.main-menu > li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}

.sub-menu li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
} 

.sub-menu li:hover {
  background-color: transparent;
}

.sub-menu > li:hover > a {
  color: #6c7ae0;
  text-decoration: none;
}

@media (max-width: 1300px) {
  .main-menu > .respon-sub-menu .sub-menu {
    right: 100%;
    left: auto;
  }

  .main-menu > .respon-sub-menu > .sub-menu {
    right: 0px;
    left: auto;
  }
}
.sub-menu-m {
  background-color: #fff;
  padding: 10px 15px 10px 32px;
  display: none;
}

.sub-menu-m a {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #666;
  line-height: 2.5;
  padding: 5px 0 5px 0;
}

.sub-menu-m a:hover {
  text-decoration: none;
  color: #555555;
}

/*------------------------------------------------------------------ Products -------------------------------------------------------------------*/
 .title-all{
     margin-bottom: 30px;
}
 .title-all h1{
     font-size: 32px;
     font-weight: 700;
     color: #000000;
}
 .title-all p{
     color: #999999;
     font-size: 16px;
}
 .products-box{
     padding: 70px 0px;
}
 .special-menu{
     margin-bottom: 40px;
}
 .filter-button-group{
     display: inline-block;
}
 .filter-button-group button{
     background: rgb(78 90 85);
     color: #ffffff;
     border: none;
     cursor: pointer;
     padding: 5px 30px;
     font-size: 18px;
}
 .filter-button-group button.active{
     background: #000000;
}
 .filter-button-group button{
}
 .products-single {
     overflow: hidden;
     position: relative;
     margin-bottom: 30px;
}
 .products-single .box-img-hover{
     overflow: hidden;
     position: relative;
}
 .box-img-hover img{
     margin: 0 auto;
     text-align: center;
     display: block;
}
 .type-lb{
     position: absolute;
     top: 0px;
     right: 0px;
     z-index:8;
}
 .type-lb .sale{
     background: rgb(78 90 85);
     color: #ffffff;
     padding: 2px 10px;
     font-weight: 700;
     text-transform: uppercase;
}
 .type-lb .new{
     background: #000000;
     color: #ffffff;
     padding: 2px 10px;
     font-weight: 700;
     text-transform: uppercase;
}
 .why-text{
     background: #ffffff;
     padding: 15px;
}
 .why-text h4{
     /* font-size: 16px;
     font-weight: 700; */
     font-size: 19px;
    font-weight: 600;
     /* padding-bottom: 15px; */
}
 .why-text h5{
     font-size: 18px;
     font-family: 'Poppins', sans-serif;
     padding: 4px;
	 display: inline-block;
	 background: rgb(78 90 85);
	 color: #ffffff;
     font-weight: 600;
}
 .mask-icon{
     width: 100%;
     height: 100%;
     position: absolute;
     overflow: hidden;
     top: 0;
     left: 0;
}
 .mask-icon ul{
     display: inline-block;
	 position: absolute;
	 bottom: 0;
	 right: 0;
}
 .mask-icon ul li{
     background: rgb(78 90 85);
}
 .mask-icon ul li a{
     color: #ffffff;
     padding: 5px 10px;
     display: block;
}
 .mask-icon a.cart{
     background: rgb(78 90 85);
     position: absolute;
     bottom: 0;
     left: 0px;
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
}
 .mask-icon a.cart:hover{
     background: #000000;
     color: #ffffff;
}
 .mask-icon ul li a:hover{
     background: #000000;
     color: #ffffff;
}
 .products-single .mask-icon{
     background: rgba(1,1,1, 0.5);
     top: -100%;
     -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
     filter: alpha(opacity=0);
     opacity: 0;
     -webkit-transition: all 0.3s ease-out 0.5s;
     -moz-transition: all 0.3s ease-out 0.5s;
     -o-transition: all 0.3s ease-out 0.5s;
     -ms-transition: all 0.3s ease-out 0.5s;
     transition: all 0.3s ease-out 0.5s;
}
 .products-single:hover .mask-icon{
     -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
     filter: alpha(opacity=100);
     opacity: 1;
     top: 0px;
     -webkit-transition-delay: 0s;
     -moz-transition-delay: 0s;
     -o-transition-delay: 0s;
     -ms-transition-delay: 0s;
     transition-delay: 0s;
     -webkit-animation: bounceY 0.9s linear;
     -moz-animation: bounceY 0.9s linear;
     -ms-animation: bounceY 0.9s linear;
     animation: bounceY 0.9s linear;
}
 @keyframes bounceY {
     0% {
         transform: translateY(-205px);
    }
     40% {
         transform: translateY(-100px);
    }
     65% {
         transform: translateY(-52px);
    }
     82% {
         transform: translateY(-25px);
    }
     92% {
         transform: translateY(-12px);
    }
     55%, 75%, 87%, 97%, 100% {
         transform: translateY(0px);
    }
}
 @-moz-keyframes bounceY {
     0% {
         -moz-transform: translateY(-205px);
    }
     40% {
         -moz-transform: translateY(-100px);
    }
     65% {
         -moz-transform: translateY(-52px);
    }
     82% {
         -moz-transform: translateY(-25px);
    }
     92% {
         -moz-transform: translateY(-12px);
    }
     55%, 75%, 87%, 97%, 100% {
         -moz-transform: translateY(0px);
    }
}
 @-webkit-keyframes bounceY {
     0% {
         -webkit-transform: translateY(-205px);
    }
     40% {
         -webkit-transform: translateY(-100px);
    }
     65% {
         -webkit-transform: translateY(-52px);
    }
     82% {
         -webkit-transform: translateY(-25px);
    }
     92% {
         -webkit-transform: translateY(-12px);
    }
     55%, 75%, 87%, 97%, 100% {
         -webkit-transform: translateY(0px);
    }
}




/*------------------------------------------------------------------ Blog -------------------------------------------------------------------*/
 .latest-blog{
     padding: 70px 0px;
     background: #f5f5f5;
}
 .blog-box{
     -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
     box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
     background: #ffffff;
     margin-bottom: 30px;
}
 .blog-content{
     position: relative;
}
 .title-blog{
     padding: 40px 30px 40px 30px;
}
 .title-blog h3{
     font-size: 20px;
     font-weight: 700;
     color: #000000;
}
 .title-blog p{
     margin: 0px;
}
 .option-blog {
     position: absolute;
     bottom: 0px;
     left: 0px;
	 right: 0px;
	 margin: 0 auto;
	 text-align: center;
}
 .option-blog li{
	 display: inline-block;
 }
 .option-blog li a{
     background: #000000;
     display: inline-block;
     font-size: 18px;
     color: #ffffff;
     width: 34px;
     height: 34px;
     text-align: center;
     line-height: 34px;
}
 .option-blog li a:hover{
     background: rgb(78 90 85);
     color: #ffffff;
}
 .tooltip-inner {
     background-color: rgb(78 90 85);
}
 .tooltip.bs-tooltip-right .arrow:before {
     border-right-color: rgb(78 90 85) !important;
     padding-top: 0px !important;
     top: 0px !important;
}
 .tooltip.bs-tooltip-left .arrow:before {
     border-left-color: rgb(78 90 85) !important;
	 padding-top: 0px !important;
	 padding-right: 5px !important;
     top: 0px !important;
}
 .tooltip.bs-tooltip-bottom .arrow:before {
     border-right-color: rgb(78 90 85) !important;
}
 .tooltip.bs-tooltip-top .arrow:before {
     border-top-color: rgb(78 90 85) !important;
	 padding-top: 0px !important;
     top: 0px !important;
}
/*------------------------------------------------------------------ Instagram Feed -------------------------------------------------------------------*/
 .instagram-box{
     padding: 70px 0px;
     /* background: url("images/ins-bg.jpg") no-repeat center center; */
     background-size: auto auto;
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -ms-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
     position: relative;
}
 .instagram-box::before{
     background: rgba(0,0,0,0.9);
     width: 100%;
     height: 100%;
     left: 0px;
     top: 0px;
     content: "" ;
     position: absolute;
     z-index: 0;
}
 .main-instagram.owl-carousel .owl-nav button.owl-prev{
     background: #000000;
     position: absolute;
     z-index: 1;
     display: block;
     height: 100%;
     width: 50px;
     line-height: 0px;
     font-size: 24px;
     cursor: pointer;
     color: #ffffff;
     top: 0;
     padding: 0;
     margin-top: 0;
     opacity: 1;
     left: 0px;
     -webkit-transition: all 0.3s ease;
     -o-transition: all 0.3s ease;
     transition: all 0.3s ease;
}
 .main-instagram.owl-carousel .owl-nav button.owl-next{
     background: #000000;
     position: absolute;
     z-index: 1;
     display: block;
     height: 100%;
     width: 50px;
     line-height: 0px;
     font-size: 24px;
     cursor: pointer;
     color: #ffffff;
     top: 0;
     padding: 0;
     margin-top: 0;
     opacity: 1;
     right: 0px;
     -webkit-transition: all 0.3s ease;
     -o-transition: all 0.3s ease;
     transition: all 0.3s ease;
}
 .main-instagram.owl-carousel .owl-nav button.owl-next:hover, .main-instagram.owl-carousel .owl-nav button.owl-prev:hover{
     background: rgb(78 90 85);
}
 .ins-inner-box{
     position: relative;
}
 .hov-in{
     opacity: 0;
     background: rgba(211,59,51, 0.5);
     bottom: -100%;
     position: absolute;
     width: 100%;
     height: 100%;
     -webkit-transition: all 0.3s ease-out 0.5s;
     -moz-transition: all 0.3s ease-out 0.5s;
     -o-transition: all 0.3s ease-out 0.5s;
     -ms-transition: all 0.3s ease-out 0.5s;
     transition: all 0.3s ease-out 0.5s;
     text-align: center;
     display: table;
}
 .hov-in a{
     display: table-cell;
     vertical-align: middle;
     height: 100%;
}
 .hov-in i{
     color: #000000;
     font-size: 48px;
     position: relative;
     z-index: 2;
}
 .hov-in a i:hover{
     color: #ffffff;
}
 .ins-inner-box:hover .hov-in{
     bottom: 0;
     opacity: 1;
}
/*------------------------------------------------------------------ Footer Main -------------------------------------------------------------------*/
 .footer-main{
     padding: 19px 0px;
     background: rgb(0, 0, 0);
}
.footer-main hr{
	border-top: 1px solid rgba(255,255,255,.6);
}
.footer-top-box{
	margin-bottom: 30px;
}
.footer-top-box h3{
	color: #ffffff;
	position: relative;
	font-size: 20px;
}
.footer-top-box h3::before {
    border-bottom: 1px solid white;
    content: "";
    height: 3px;
    width: 100%;
    /* width: 50%; */
    position: absolute;
    bottom: 3px;
    left: 0px;
}
.list-time{
	margin-top: 15px;
}
.footer-top-box .list-time li{
	color: #ffffff;
	font-size: 16px;
	display: block;
	float: none;
}

.newsletter-box{
	margin-top: 15px;
	position: relative;
}
.newsletter-box input{
	height: 40px;
	border: none;
	padding: 0 10px;
	background: transparent;
	border-radius: 0;
	background: white !important;
	padding: 0 20px;
	width: 100%;
}
.newsletter-box i{
	position: absolute;
	right: 15px;
	top: 10px;
	font-size: 20px;
}
.newsletter-box button{	
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	
}
 .footer-top-box p{
	 padding-bottom: 15px;
	 color: #cccccc;
 }

 .footer-top-box ul{
     display: inline-block;
}
 .footer-top-box ul li {
     float: left;
     margin-right: 5px;
}
 .footer-top-box ul li a {
     color: #ffffff;
     display: inline-block;
     width: 36px;
     height: 36px;
     border: 2px solid #ffffff;
     text-align: center;
     line-height: 32px;
}
 .footer-top-box ul li a:hover {
     color: #F2AA4CFF;
     border-color: #F2AA4CFF;
}
.footer-widget, .footer-link, .footer-link-contact{
	/* margin-top: 15px; */
}
 .footer-widget h4 {
     color: #ffffff;
     font-size: 16px;
     text-transform: uppercase;
     letter-spacing: 2px;
     margin-bottom: 20px;
     position: relative;
     font-weight: 700;
}
 .footer-widget h4::before {
     border-bottom: 3px solid white;
     content: "";
     height: 3px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0px;
}
 .footer-widget p {
     color: #cccccc;
     font-weight: 400;
     font-size: 14px;
     padding-bottom: 20px;
}

 .footer-link{
}
 .footer-link h4{
     color: #ffffff;
     font-size: 16px;
     text-transform: uppercase;
     letter-spacing: 2px;
     /* margin-bottom: 20px; */
     position: relative;
     font-weight: 700;
}
 .footer-link h4::before {
     border-bottom: 1px solid white;
     content: "";
     height: 3px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0px;
}
 .footer-link ul li {
     margin-right: 5px;
}
 .footer-link ul li a {
     color: #ffffff;
     text-align: left;
     display: block;
     line-height: 32px;
     position: relative;
     padding-left: 15px;
     transition: all .3s ease-in-out;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
}
 /* .footer-link ul li a::before {
     font-family: FontAwesome;
     content: "\f105";
     position: absolute;
     left: 5px;
     line-height: 35px;
     font-size: 16px;
     transition: all .3s ease-in-out;
     -webkit-transition: all .3s ease-in-out;
     -moz-transition: all .3s ease-in-out;
     -o-transition: all .3s ease-in-out;
     -ms-transition: all .3s ease-in-out;
} */
 .footer-link ul li a:hover::before {
     left: 5px;
}
 .footer-link ul li a:hover {
     color: #F2AA4CFF;
     border-color: #F2AA4CFF;
     padding-left: 20px;
}
 .footer-link-contact h4{
     color: #ffffff;
     font-size: 16px;
     text-transform: uppercase;
     letter-spacing: 2px;
     margin-bottom: 20px;
     position: relative;
     font-weight: 700;
}
 .footer-link-contact h4::before {
     border-bottom: 3px solid white;
     content: "";
     height: 3px;
     width: 100%;
     position: absolute;
     bottom: 3px;
     left: 0px;
}
 .footer-link-contact ul li{
     margin-bottom: 12px;
}
 .footer-link-contact ul li i{
     position: absolute;
     left: 0;
     top: 5px;
     padding-right: 6px;
}
 .footer-link-contact ul li p{
     padding-left: 25px;
     color: #cccccc;
     position: relative;
}
 .footer-link-contact ul li p{
     font-size: 16px;
     color: #cccccc;
     font-weight: 300;
     padding-right: 16px;
     line-height: 24px;
}
 .footer-link-contact ul li p a{
     color: #cccccc;
}
 .footer-link-contact ul li p a:hover{
     color: F2AA4CFF;
}
/*------------------------------------------------------------------ Copyright -------------------------------------------------------------------*/
 .footer-copyright{
   border-top: 2px solid rgb(51, 51, 51) !important;
     background: rgb(0, 0, 0);
     padding: 20px 0px;
     position: relative;
}
 .footer-copyright p {
     text-align: center;
     color: #ffffff;
     font-size: 16px;
}
 .footer-copyright p a{
     color: #ffffff;
}
 .footer-copyright p a:hover{
     color: F2AA4CFF;
}
/*------------------------------------------------------------------ All Pages -------------------------------------------------------------------*/
 .all-title-box{
     /* background: url("images/all-bg-title.jpg") no-repeat center center; */
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -ms-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
     text-align: center;
     background-attachment: fixed;
     padding: 70px 0px;
     position: relative;
}
 .all-title-box::before{
     background: rgba(0,0,0,0.6);
     content: "";
     position: absolute;
     z-index: 0;
     width: 100%;
     height: 100%;
     left: 0px;
     top: 0px;
}
 .all-title-box h2{
     font-size: 28px;
     font-weight: 700;
     color: #ffffff;
	 float: left;
     padding: 10px 0px;
}
 .all-title-box .breadcrumb{
     background: rgb(78 90 85);
     margin: 0px;
     display: inline-block;
     border-radius: 0px;
	 float: right;
}
 .all-title-box .breadcrumb li{
     display: inline-block;
     color: #000000;
     font-size: 16px;
}
 .all-title-box .breadcrumb li a{
     color: #ffffff;
     font-size: 16px;
}
 .all-title-box .breadcrumb-item + .breadcrumb-item::before{
     color: #000000;
}
 .about-box-main{
     padding: 70px 0px;
}
 .noo-sh-title{
     font-size: 28px;
     text-transform: uppercase;
     font-style: normal;
     font-weight: 700;
	 margin-bottom: 30px;
}
.noo-sh-title-top{
     font-size: 28px;
     text-transform: uppercase;
     font-style: normal;
     font-weight: 700;
	 margin-bottom: 0px;
}
 .about-box-main p{
     padding-bottom: 20px;
}
 .service-block-inner {
     padding: 15px 20px;
     position: relative;
     margin-bottom: 30px;
}

.about-box-main a{
	color: #ffffff;
	font-size: 18px;
	font-weight: 700;
	border: none;
}

.about-box-main a.btn{
	padding: 12px 15px;
}

 .service-block-inner::before {
     content: "";
     width: 100%;
     height: 5px;
     border-radius: 0px;
     background: #000000;
     position: absolute;
     top: 0;
     left: 0;
     transition: all 0.5s ease 0s;
}
 .service-block-inner::after {
     content: "";
     width: 100%;
     height: 0;
     border-radius: 0px;
     background: rgb(78 90 85);
     position: absolute;
     top: 0;
     left: 0;
	 z-index: -1;
     transition: all 0.5s ease 0s;
}
 .service-block-inner h3 {
     font-size: 24px;
     text-transform: uppercase;
     font-weight: 600;
}
 .service-block-inner p {
     margin: 0px;
     font-size: 16px;
     font-weight: 300;
     padding-bottom: 0px;
}
 .service-block-inner:hover::after {
     height: 100%;
}

.service-block-inner:hover h3{
	color: #ffffff;
	transition: all 0.5s ease 0s;
}

.service-block-inner:hover p{
	color: #ffffff;
	transition: all 0.5s ease 0s;
}

 .our-team{
     box-shadow: 0 0 0 rgba(0, 0, 0, 0.3);
     overflow: hidden;
     position: relative;
     transition: all 0.3s ease 0s;
}
 .our-team:hover{
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
 .our-team img{
     width: 100%;
     height: auto;
     transition: all 0.3s ease-in-out 0s;
}
 .our-team:hover img{
     opacity: 0.5;
}
 .our-team .team-content{
     color: #000;
     opacity: 1;
     position: absolute;
     bottom: 0px;
     left: 0px;
	 background: #ffffff;
	 padding-left: 55px;
	 width: 100%;
     transition: all 0.3s ease 0s;
}
 .our-team .title{
     display: block;
     font-size: 20px;
     font-weight: 700;
     margin: 0 0 7px 0;
}
 .our-team .post{
     display: block;
     font-size: 15px;
}
 .our-team .social{
     list-style: none;
     padding: 0;
     margin: 0;
     width: 35px;
     text-align: center;
     opacity: 0;
     position: absolute;
     bottom: 35px;
	 right: 0px;
     transition: all 0.3s ease 0s;
}
 .our-team:hover .social{
     opacity: 1;
}
 .our-team .social li{
     display: block;
}
 .our-team .social li a{
     display: inline-block;
     width: 35px;
     height: 35px;
     line-height: 35px;
     background: #000000;
     font-size: 17px;
     color: #fff;
     opacity: 0.9;
     position: relative;
     transform: translate(35px, -35px);
     transition: all 0.3s ease-in-out 0s 
}
 .our-team .social li a:hover{
     width: 35px;
     background: rgb(78 90 85);
     transition-delay: 0s;
}
 .our-team .icon{
     width: 35px;
     height: 35px;
     line-height: 35px;
     background: rgb(78 90 85);
     text-align: center;
     color: #fff;
     position: absolute;
     bottom: 0;
}
 .team-description{
     padding: 20px 0px;
}
 .team-description p{
     font-size: 14px;
     margin: 0px;
}
 .hover-team:hover .social{
     opacity: 1;
}
 .hover-team:hover .social li:nth-child(1) a{
     transition-delay: 0.3s;
}
 .hover-team:hover .social li:nth-child(2) a{
     transition-delay: 0.2s;
}
 .hover-team:hover .social li:nth-child(3) a{
     transition-delay: 0.1s;
}
 .hover-team:hover .social li:nth-child(4) a{
     transition-delay: 0.0s;
}
 .hover-team:hover .social li a{
     transform: translate(0, 0) 
}
 .hover-team:hover img{
     opacity: 0.5;
}
 .hover-team .team-content .title{
     border-bottom: 2px solid rgb(78 90 85);
}
 .shop-box-inner{
     padding: 70px 0px;
}
 .search-product {
     position: relative;
}
 .search-product input[type="text"] {
     background: #333333;
     border: 0;
     box-shadow: none;
     border-radius: 0;
     color: #ffffff;
     height: 55px;
     font-weight: 300;
     font-size: 16px;
     margin-bottom: 15px;
     padding: 0 20px;
     -webkit-transition: all .5s ease;
     -moz-transition: all .5s ease;
     transition: all .5s ease;
     width: 100%;
     outline: 0;
}
 .search-product .form-control::-moz-placeholder {
     color: #ffffff;
     opacity: 1;
}
 .search-product button {
     background: #000000;
     color: #ffffff;
     font-size: 18px;
     position: absolute;
     right: 0px;
     padding: 12px 15px;
     top: 0;
     line-height: 27px;
     border: none;
     cursor: pointer;
     height: 100%;
}
 .search-product button:hover{
     background: rgb(78 90 85);
}
 .filter-sidebar-left{
     margin-bottom: 20px;
}
 .title-left{
     font-size: 16px;
     border-bottom: 3px solid #000000;
     margin-bottom: 20px;
}
 .title-left h3{
     font-weight: 700;
}
 .list-group-item {
     border: none;
     padding: 5px 20px;
     font-size: 14px;
}
 .text-muted {
     padding: 10px 0px;
}
 .list-group-item[data-toggle="collapse"]::after {
     width: 0;
     height: 0;
     position: absolute;
     top: calc(50% - 12px);
     right: 10px;
     content: '';
     -webkit-transition: top .2s,-webkit-transform .2s;
     transition: top .2s,-webkit-transform .2s;
     transition: transform .2s,top .2s;
     transition: transform .2s,top .2s,-webkit-transform .2s;
     font-family: FontAwesome;
     content: "\f105";
}
 .list-group-tree .list-group-collapse .list-group {
     margin-left: 25px;
     border-left: 1px solid #ced4da;
}
 .list-group-tree .list-group-item.active {
     color: rgb(78 90 85);
     background-color: #fff;
     font-weight: 700;
}
 .list-group-tree .list-group-collapse .list-group > .list-group-item::before {
     position: absolute;
     top: 14px;
     left: 0;
     content: '';
     width: 8px;
     height: 1px;
     background-color: #ced4da;
}
 .list-group-tree .list-group-item:hover {
     color: rgb(78 90 85);
     background-color: #fff;
     outline: 0;
     font-weight: 700;
}
 .filter-price-left{
     margin-bottom: 20px;
}
 #slider-range .ui-slider-handle {
     background-color: rgb(78 90 85);
     border: 2px solid #fff;
     border-radius: 50%;
     cursor: pointer;
     height: 21px;
     top: -6px;
     transition: none 0s ease 0s;
     width: 21px;
     box-shadow: 0px 0px 6.65px 0.35px rgba(0,0,0,0.15);
}
 #slider-range .ui-slider-range {
     background-color: rgb(78 90 85);
     border-radius: 0;
}
 #slider-range {
     background: #000000;
     border: medium none;
     border-radius: 50px;
     float: left;
     height: 10px;
     margin-top: 14px;
     width: 100%;
}
 .price-box-slider p{
     clear: both;
     margin-top: 20px;
     display: inline-block;
     width: 100%;
}
 .price-box-slider p input{
     margin-top: 5px;
}
 .price-box-slider p button{
     border: none;
     color: #ffffff;
     float: right;
}
 .brand-box {
     display: inline-block;
     width: 100%;
     height: 259px;
     position: relative;
}
 .product-item-filter{
     border-bottom: 1px solid #000000;
     border-top: 1px solid #000000;
     display: -webkit-box;
     display: -ms-flexbox;
     display: -webkit-flex;
     display: flex;
     -webkit-box-pack: justify;
     -ms-flex-pack: justify;
     -webkit-justify-content: space-between;
     justify-content: space-between;
     padding: 5px 0;
}
 .nice-select.wide{
     width: 75%;
}
 .product-item-filter .toolbar-sorter-right span{
     line-height: 42px;
     padding-right: 15px;
     float: left;
}
 .product-item-filter .toolbar-sorter-right{
     width: 65%;
}
 .toolbar-sorter-right{
     float: left;
}
 .toolbar-sorter-right .bootstrap-select.form-control:not([class*="col-"]){
     width: 77%;
     float: right;
}
 .toolbar-sorter-right .bootstrap-select.btn-group > .dropdown-toggle{
     padding: 0px;
     border-radius: 0px;
     border: none;
}
 .toolbar-sorter-right .bootstrap-select.btn-group .dropdown-toggle .filter-option{
     padding-left: 15px;
}
 .toolbar-sorter-right .btn-light{
     background: #000000;
     color: #ffffff;
}
 .toolbar-sorter-right .btn-light:hover{
     background: rgb(78 90 85);
     border-radius: 0px;
     border: none;
}
 .toolbar-sorter-right .show > .btn-light.dropdown-toggle{
     background-color: rgb(78 90 85);
}
 .toolbar-sorter-right .bootstrap-select .dropdown-toggle:focus{
     background: rgb(78 90 85);
}
 .toolbar-sorter-right .dropup .dropdown-toggle::after{
     position: absolute;
     right: 15px;
     top: 18px;
}
 .product-item-filter p{
     float: right;
     line-height: 42px;
}
 .product-item-filter .nav-tabs{
     border: none;
     float: right;
}
 .nav > li {
     position: relative;
     display: inline-block;
     vertical-align: middle;
}
 .product-item-filter li .nav-link {
     border: none;
     border-radius: 0px;
     color: #111111;
     font-size: 18px;
     padding: 4px 12px;
}
 .product-item-filter li .nav-link.active {
     background: rgb(78 90 85);
     border: none;
     color: #ffffff;
}
 .product-item-filter li .nav-link:hover {
     background: #F2AA4CFF;
     border: none;
     color: #ffffff;
}
 .product-categori{
     margin-bottom: 30px;
}
 .product-categorie-box{
     margin-top: 20px;
}
 .tab-content, .tab-pane{
     width: 100%;
}
 .why-text.full-width h4 {
     font-size: 24px;
     font-weight: 700;
     padding-bottom: 15px;
}
 .why-text.full-width h5 del{
     font-size: 13px;
     color: #666;
}
 .why-text.full-width h5{
     color: #000000;
     font-weight: 700;
}
 .why-text.full-width p{
     padding-bottom: 20px;
}
 .why-text.full-width a{
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .list-view-box{
     margin-bottom: 30px;
}
 .list-view-box:hover .mask-icon{
     -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
     filter: alpha(opacity=100);
     opacity: 1;
     top: 0px;
     -webkit-transition-delay: 0s;
     -moz-transition-delay: 0s;
     -o-transition-delay: 0s;
     -ms-transition-delay: 0s;
     transition-delay: 0s;
     -webkit-animation: bounceY 0.9s linear;
     -moz-animation: bounceY 0.9s linear;
     -ms-animation: bounceY 0.9s linear;
     animation: bounceY 0.9s linear;
}
 .cart-box-main{
     padding: 70px 0px;
}
 .table-main table thead{
     background: rgb(78 90 85);
     color: #ffffff;
}
 .table-main .table thead th{
     font-size: 18px;
}
 .table-main table td.thumbnail-img{
     width: 120px;
}
 .table-main table td{
     vertical-align: middle;
     font-size: 16px;
}
 .quantity-box input{
     width: 60px;
     border: 2px solid #000000;
     text-align: center;
}
 .quantity-box input:focus{
     border-color: rgb(78 90 85);
}
 .name-pr a{
     font-weight: 700;
     font-size: 18px;
     color: #000000;
}
 .remove-pr{
     text-align: center;
}
 .coupon-box .input-group .form-control {
     min-height: 50px;
     border-radius: 0px;
     font-weight: 400;
     border: 1px solid #e8e8e8;
     box-shadow: none !important;
}
 .coupon-box .input-group .form-control::-moz-placeholder{
     color: #000000;
}
 .coupon-box .input-group .input-group-append .btn-theme {
     background: #000000;
     color: #ffffff;
     border: none;
     border-radius: 0px;
     font-size: 16px;
     padding: 0px 20px;
}
 .coupon-box .input-group .input-group-append .btn-theme:hover {
     background: rgb(78 90 85);
}
 .update-box input[type="submit"]{
     background: #000000;
     border: medium none;
     border-radius: 0;
     -webkit-box-shadow: none;
     box-shadow: none;
     color: #fff;
     display: inline-block;
     float: right;
     cursor: pointer;
     font-size: 16px;
     font-weight: 500;
     height: 50px;
     line-height: 40px;
     margin-right: 15px;
     padding: 0 20px;
     text-shadow: none;
     text-transform: uppercase;
     -webkit-transition: all 0.3s ease 0s;
     transition: all 0.3s ease 0s;
     white-space: nowrap;
     width: inherit;
}
 .update-box input[type="submit"]:hover{
     background: rgb(78 90 85);
}
 .order-box h3{
     font-size: 16px;
     color: #222222;
     font-weight: 700;
}
 .order-box h4 {
     font-size: 16px;
     padding: 0px;
     line-height: 35px !important;
}
 .order-box .font-weight-bold{
     font-size: 18px;
}
 .gr-total h5 {
     font-weight: 700;
     color: rgb(78 90 85);
     font-size: 18px;
     margin: 0px;
     padding: 0px;
     line-height: 35px !important;
}
 .gr-total .h5{
     margin: 0px;
     font-weight: 700;
     line-height: 35px;
}
 .my-account-box-main{
     padding: 70px 0px;
}
 .shopping-box a{
     font-size: 18px;
     color: #ffffff;
     border: none;
     padding: 10px 20px;
}
 .payment-icon {
     display: inline-block;
     padding: 10px 0px;
}
 .payment-icon ul li {
     width: 20%;
     float: left;
}
 .needs-validation label {
     font-size: 16px;
     margin-bottom: 0px;
     line-height: 24px;
}
 .needs-validation .form-control {
     min-height: 40px;
     border-radius: 0px;
     border: 1px solid #e8e8e8;
     box-shadow: none !important;
     font-size: 14px;
}
 .needs-validation .form-control:focus {
     border: 1px solid rgb(78 90 85) !important;
}
 .review-form-box .form-control{
     min-height: 40px;
     border-radius: 0px;
     border: 1px solid #e8e8e8;
     box-shadow: none !important;
     font-size: 14px;
}
 .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
     background-color: rgb(78 90 85);
     box-shadow: none;
}
 .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
     background-color: rgb(78 90 85);
     box-shadow: none;
}
 .new-account-login h5 {
     font-size: 18px;
     color: #111111;
     font-weight: 600;
}
 .new-account-login h5 a:hover{
     color: rgb(78 90 85);
}
 .review-form-box button{
     padding: 10px 20px;
     color: #ffffff;
     font-size: 18px;
     border: none;
}
 .wide.w-100{
     min-height: 40px;
     border: 1px solid #e8e8e8;
}
 .wide.w-100 option{
     min-height: 40px;
}
 .custom-control-input:focus ~ .custom-control-label::before{
     box-shadow: none;
}
 .odr-box a {
     font-size: 18px;
     color: #111111;
     font-weight: 700;
}
 .account-box{
     text-align: center;
     background: #ffffff;
     padding: 30px;
     border: 1px solid #000000;
}
 .bottom-box {
     border-top: 1px solid #eee;
     margin-bottom: 30px;
     margin-top: 30px;
     padding-top: 15px;
}
 .bottom-box .account-box {
     min-height: 205px;
}
 .account-box {
     border: 2px solid #000000;
     margin-top: 15px;
}
 .my-account-page a{
     color: #000000;
}
 .my-account-page a:hover{
     color: rgb(78 90 85);
}
 .service-icon i{
     font-size: 34px;
}
 .my-account-page a:hover i{
}
 .service-desc p{
     font-size: 16px;
}
 .service-desc h4{
     text-decoration: underline;
     font-size: 18px;
     font-weight: 700;
}
 .service-icon a{
     background: rgba(0, 0, 0, 0.9);
     -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
     -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
     transition: transform ease-out 0.1s, background 0.2s;
}
 .service-icon a{
     display: inline-block;
     font-size: 0px;
     cursor: pointer;
     margin: 15px 0px;
     width: 90px;
     height: 90px;
     line-height: 110px;
     border-radius: 50%;
     text-align: center;
     position: relative;
     z-index: 1;
     color: #ffffff;
}
 .service-icon a::after {
     pointer-events: none;
     position: absolute;
     width: 100%;
     height: 100%;
     border-radius: 50%;
     content: '';
     -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
     box-sizing: content-box;
     content: "";
     top: 0;
     left: 0;
     padding: 0;
     z-index: -1;
     box-shadow: 0 0 0 2px rgba(255,255,255,0.1);
     opacity: 0;
     -webkit-transform: scale(0.9);
     -moz-transform: scale(0.9);
     -ms-transform: scale(0.9);
     transform: scale(0.9);
}
 .service-icon a:hover::after{
     -webkit-animation: sonarEffect 1.3s ease-out 75ms;
     -moz-animation: sonarEffect 1.3s ease-out 75ms;
     animation: sonarEffect 1.3s ease-out 75ms;
}
 .service-icon a:hover{
     background: rgba(251, 183, 20, 1);
     -webkit-transform: scale(0.93);
     -moz-transform: scale(0.93);
     -ms-transform: scale(0.93);
     transform: scale(0.93);
     color: #fff;
}
 @-webkit-keyframes sonarEffect {
     0% {
         opacity: 0.3;
    }
     40% {
         opacity: 0.5;
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(78 90 85), 0 0 0 10px rgba(0, 53, 68, 0.5);
    }
     100% {
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(78 90 85), 0 0 0 10px rgba(0, 53, 68, 0.5);
         -webkit-transform: scale(1.5);
         opacity: 0;
    }
}
 @-moz-keyframes sonarEffect {
     0% {
         opacity: 0.3;
    }
     40% {
         opacity: 0.5;
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(78 90 85), 0 0 0 10px rgba(0, 53, 68, 0.5);
    }
     100% {
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(78 90 85), 0 0 0 10px rgba(0, 53, 68, 0.5);
         -moz-transform: scale(1.5);
         opacity: 0;
    }
}
 @keyframes sonarEffect {
     0% {
         opacity: 0.3;
    }
     40% {
         opacity: 0.5;
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px rgb(78 90 85), 0 0 0 10px rgba(0, 53, 68, 0.5);
    }
     100% {
         box-shadow: 0 0 0 2px rgba(0, 53, 68, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(0, 53, 68, 0.5);
         transform: scale(1.5);
         opacity: 0;
    }
}
 .add-pr a{
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .wishlist-box-main{
     padding: 70px 0px;
}
 .services-box-main{
     padding: 70px 0px;
}
 .contact-box-main{
     padding: 70px 0px;
}
 .contact-info-left{
     padding: 20px;
     /* background: url("images/contact-bg.jpg") no-repeat center; */
	 background-size: cover;
}
 .contact-info-left h2{
     font-size: 22px;
     font-weight: 700;
}
 .contact-info-left p{
     margin-bottom: 15px;
}
 .contact-info-left ul li{
     margin-bottom: 12px;
}
 .contact-info-left ul li p{
     font-size: 16px;
     color: #222222;
     font-weight: 300;
     padding-right: 16px;
     padding-left: 25px;
     line-height: 24px;
     position: relative;
}
 .contact-info-left ul li p i{
     position: absolute;
     left: 0;
     top: 5px;
     padding-right: 6px;
     color: rgb(78 90 85);
}
 .contact-info-left ul li p a:hover{
     color: rgb(78 90 85);
}
 .contact-form-right{
     padding: 20px;
}
 .contact-form-right h2{
     font-size: 24px;
     font-weight: 700;
}
 .contact-form-right p{
     margin-bottom: 15px;
}
 .contact-form-right .form-group .form-control::-moz-placeholder{
     color: #999999;
}
 .contact-form-right .form-group .form-control{
     border-radius: 0px;
     min-height: 40px;
}
 .contact-form-right .form-group {
     margin-bottom: 30px;
     position: relative;
}
 .contact-form-right .form-group .form-control:focus{
     border: 1px solid rgb(78 90 85);
     box-shadow: none;
}
 .submit-button button{
     padding: 10px 20px;
     font-weight: 700;
     color: #ffffff;
     border: none;
}
 .help-block.with-errors{
     position: absolute;
     right: 0;
     background: red;
     color: #fff;
     padding: 0px 15px;
}
 .help-block ul li{
     color: #fff;
}
 .shop-detail-box-main{
     padding: 70px 0px;
}
 .single-product-slider .carousel-control-prev {
     bottom: auto;
     background: #111111;
     width: 6%;
     padding: 10px 0;
     background-image: none;
     top: 40%;
}
 .single-product-slider .carousel-control-next {
     bottom: auto;
     background: #111111;
     width: 6%;
     padding: 10px 0;
     background-image: none;
     top: 40%;
}
 .single-product-slider .carousel-indicators li img {
     opacity: 0.5;
}
 .single-product-slider .carousel-indicators li.active img {
     opacity: 1;
}
 .carousel-indicators{
  width: 100%;
     position: relative;
     bottom: 0;
     /* background: #000000; */
}
 .carousel-indicators li{
     width: 30%;
     height: 100%;
     cursor: pointer;
}
 .carousel-indicators li{
     margin-left: 0px !important;
}
 .single-product-details h2{
     color: #000000;
     font-weight: 700;
     font-size: 24px;
}
 .single-product-details h5 {
     color: black;
     font-weight: 500;
     font-size: 18px;
}
 .single-product-details h5 del {
     font-size: 21px;
     /* font-size: 13px; */
     color: #666;
}
 .available-stock span {
     font-size: 15px;
}
 .available-stock span a {
     color: rgb(78 90 85);
}
 .single-product-details h4{
     font-size: 18px;
     font-weight: 700;
     margin-top: 20px;
}
 .single-product-details p{
     font-size: 19px;
     /* font-size: 16px; */
     margin-bottom: 20px;
}
 .single-product-details ul{
     display: inline-block;
     width: 100%;
     /* border: 1px #000000 dashed; */
     margin-bottom: 30px;
}
 .single-product-details ul li{
     /* width: 50%; */
     float: left;
     padding: 0px 3px;
}
 .size-st .bootstrap-select > .dropdown-toggle.btn-light{
     background: #000000;
     border: none;
     border-radius: 0px;
     color: #ffffff;
}
 .quantity-box input{
     width: 100%;
}
 .price-box-bar{
     margin-bottom: 30px;
}
 .price-box-bar a{
     padding: 5px 30px;
     /* font-weight: 700; */
     color: #ffffff;
     border: none;
}
 .add-to-btn .add-comp {
     float: left;
}
 .add-to-btn .share-bar {
     float: right;
}
 .add-comp a{
     padding: 10px 20px;
     /* font-weight: 700; */
     color: #ffffff;
     border: none;
}
 .share-bar{
}
 .share-bar a {
     background: rgb(78 90 85);
     color: #ffffff;
     padding: 5px 10px;
     display: inline-block;
     width: 34px;
     text-align: center;
}
 .share-bar a {
}
 .featured-products-box{
}
 .featured-products-box .owl-nav{
     position: absolute;
     margin: 0 auto;
     left: 0;
     right: 0;
     bottom: -20px;
     text-align: center;
}
 .featured-products-box .owl-nav button.owl-prev{
     background: #000000;
     color: #ffffff;
     width: 38px;
     height: 38px;
     text-align: center;
}
 .featured-products-box .owl-nav button.owl-next{
     background: #000000;
     color: #ffffff;
     width: 38px;
     height: 38px;
     text-align: center;
}
 .featured-products-box .owl-nav button.owl-prev:hover, .featured-products-box .owl-nav button.owl-next:hover{
     background: rgb(78 90 85);
}
 .table-responsive .table td, .table-responsive .table th {
     vertical-align: middle;
     white-space: nowrap;
}
 
.card-outline-secondary .card-body a{
	color: #ffffff;
	font-size: 18px;
	font-weight: 700;
	padding: 12px 25px;
	border: none;
}







.dfkj1{
  display: none !important;
}


.img123{
  height: 307px;
}
.hov-img0 img {
  width: 100%;
  transition: transform 0.9s ease;
}
.hov-img0 {
  display: block;
  overflow: hidden;
}
.block2-pic {
  position: relative;
}
.block2-btn {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
}
.stext-103 {
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 1.466667;
}
.size-102 {
  min-width: 139px;
  height: 40px;
}
.cl2 {
  color: #333;
}
.bg0 {
  background-color: #fff;
}
.bor2 {
  border-radius: 20px;
}
.trans-04 {
  transition: all 0.4s;
}
.flex-c-m {
  justify-content: center;
  /* -ms-align-items: center  ; */
  align-items: center;
}
.carrousel-item-copy{
  width: 98% ;
}
.js-btn-minus:hover{
  background-color: #F2AA4CFF;
}
.js-btn-plus:hover{
  background-color: #F2AA4CFF;
}
@media  only screen and (min-width: 530px) and (max-width: 1012px){
  .display-change-none{
    display: none;
  }
  /* .carrousel-item-copy{
    width: 85% ;
  } */
  
}
.change-plus{
  display: none;
}
@media  only screen and (min-width: 526px) and (max-width: 990px){
  
.change-plus{
  display: block;
}
}
.width-change{
  width : 30px !important
}
.change-button-style{
  color  :#285a73 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.change-button-style1{
  background-color  :#285a73 !important;
  color  :white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.change-line-height-span{
  line-height: 134%!important;

}
@media  only screen and (min-width: 200px) and (max-width: 525px){
  .change-height-mobile-slider{
    height : 300px !important
  }
  .change-plus{
    display: block;
  }
  /* nav.navbar .navbar-brand{
    left :30% !important
} */
/* .sub-menu4{
  display:  block;
}
.sub-menu3{
  display:  block;
} */
.sub-menu1{
  display:  none !important;
}
.sub-menu{
  display:  none !important;
}

.nav-item{
    padding  :0px !important
  }
  .change-text-size{
    font-size: 21px !important;
    left : 5% !important;
  }
  .display-change-none{
    display: none;
  }
  .change-align-flex{
    justify-content: center;
  }
  
  .align-change{
    text-align: center;
  }
  .change-arrow{
    right :30px !important; 
  }
  .change-arrow1{
    right :16% !important; 
  }
  .carrousel-item-copy{
    width: 88% ;
  }
  .change-height{
    height:300px !important ;
  }
  .img-cov-copy{
    height :300px !important;
  }
  .dfkjdf{
    height: 270px !important;
  }
  .attr-nav{
    top: 20px !important;
    right: 30px !important;   
    /* right: 10px !important;    */
  }

  .img123{
    height: auto !important;
  }
  .dfkj{
    display: none !important;
  }
  .dfkj1{
    display: block !important;
  }
  .ther{
    top :  -296px !important;
    position: absolute;
    padding : 0px !important
      }
  .ther1{
    font-size :  30px !important
      }
  .ther3{
    height: 420px;
      }
  .ther4{
    max-height: 420px !important;
      }
      .efeeee{
        height: auto !important;
        object-fit: contain !important;
      }
    }

    .top-search {
      display:  block;
 }










 .the{
   height: 430px !important;
   /* object-fit: cover !important; */
 }


 .the1{
   height: 100px !important;
 }

 .hr-btn:hover{
   background-color: black !important;
   transition: .6s !important;
 }
 .special-list{
   height: auto !important;
 }



 .icon-container {
  margin-bottom: 20px;
  padding: 7px 0;
  font-size: 24px;
}

.inputAll{
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.col-50-payment {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
}
.slick-arrow {
  background-color: black;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  z-index: 99;
}
.slick-arrow:hover,
.slick-arrow:active,
.slick-arrow:focus {
  background-color: #e7ab3c !important;
}



.slick-slide img {
  margin: auto;
  width: 100%;
  height: 50%;
}



.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 300px;
  height: 300px;
}


.fabs {
  bottom: 0;
  position: fixed;
  margin: 1em;
  right: 0;
  z-index: 998;
  
}

.fab {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  text-align: center;
  color: #f0f0f0;
  margin: 25px auto 16px;
  box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
  cursor: pointer;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  position: relative;
  z-index: 998;
  overflow: hidden;
  background:#82898f;
}

.fab > i {
  font-size: 2em;
  line-height: 55px;
  -webkit-transition: all .2s ease-out;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.fab:not(:last-child) {
  width: 0;
  height: 0;
  margin: 20px auto 0;
  /* opacity: 0;
  visibility: hidden; */
  line-height: 40px;
}

.fab:not(:last-child) > i {
  font-size: 1.4em;
  line-height: 40px;
}

.fab:not(:last-child).is-visible {
  width: 40px;
  height: 40px;
  margin: 15px auto 10;
  opacity: 1;
  visibility: visible;
}

.fab:nth-last-child(1) {
  -webkit-transition-delay: 25ms;
  transition-delay: 25ms;
}

.fab:not(:last-child):nth-last-child(2) {
  -webkit-transition-delay: 20ms;
  transition-delay: 20ms;
}

.fab:not(:last-child):nth-last-child(3) {
  -webkit-transition-delay: 40ms;
  transition-delay: 40ms;
}

.fab:not(:last-child):nth-last-child(4) {
  -webkit-transition-delay: 60ms;
  transition-delay: 60ms;
}

.fab:not(:last-child):nth-last-child(5) {
  -webkit-transition-delay: 80ms;
  transition-delay: 80ms;
}

.fab(:last-child):active,
.fab(:last-child):focus,
.fab(:last-child):hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
}
/*Chatbox*/

#chat1 {
  display:  none; 
}
.chat {
  position: fixed;
  right: 85px;
  bottom: 20px;
  width: 400px;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Roboto';
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  /* opacity: 0; */
  /* visibility: hidden; */
  /* display:  none; */
  box-shadow: 1px 1px 100px 2px rgba(0, 0, 0, 0.22);
  border-radius: 10px;
  -webkit-transition: all .2s ease-out;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.chat_fullscreen {
    position: fixed;
    right: 0px;
    bottom: 0px;
    top: 0px;
  }
.chat_header {
      /* margin: 10px; */
    font-size: 13px;
    font-family: 'Roboto';
    font-weight: 500;
    color: #f3f3f3;
    height: 55px;
    background: #41464a;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 8px;
}
.chat_header2 {
      /* margin: 10px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.chat_header .span {
  float:right;
}

.chat_fullscreen_loader {
  display: none;
    float: right;
    cursor: pointer;
    /* margin: 10px; */
    font-size: 20px;
    opacity: 0.5;
    /* padding: 20px; */
    margin: -10px 10px;
}

.chat.is-visible {
  opacity: 1;
  -webkit-animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .2s cubic-bezier(.42, 0, .58, 1);
}
.is-hide{
  opacity: 0
}

.chat_option {
  /* float: left; */
  font-size: 15px;
  list-style: none;
  position: relative;
  height: 100%;
  width: 89%;
  text-align: relative;
  margin-right: 10px;
  letter-spacing: 0.5px;
  font-weight: 400;
  margin : auto;
}


.chat_option img {
    border-radius: 50%;
    width: 55px;
    float: left;
    margin: -4px 6px 10px 7px;
    border: 4px solid rgba(0, 0, 0, 0.21);
}

.change_img img{
    width: 35px;
    margin: 0px 20px 0px 20px;
}
.chat_option .agent {
  font-size: 12px;
    font-weight: 300;
}
.chat_option .online{
      opacity: 0.4;
    font-size: 11px;
    font-weight: 300;
}
.chat_color {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 10px;
  float: left;
}


.chat_body {
  background: #fff;
  width: 100%;

  display: inline-block;
  text-align: center;
    overflow-y: auto;

}
#chat_body{
    height: 450px;
}
.chat_login p,.chat_body li, p, a{
    -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
}
.chat_body p {
  padding: 20px;
  color: #888
}
.chat_body a {
  width: 10%;
  text-align: center;
  border: none;
  box-shadow: none;
  line-height: 40px;
  font-size: 15px;
}



.chat_field {
  position: relative;
  margin: 5px 0 5px 0;
  width: 50%;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
  color: #4b4b4b;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  border: none;
  outline: none;
  display: inline-block;
}

.chat_field.chat_message {
  height: 30px;
  resize: none;
      font-size: 13px;
    font-weight: 400;
}
.chat_category{
  text-align: left;
}

.chat_category{
  margin: 20px;
  background: rgba(0, 0, 0, 0.03);
  padding: 10px;
}

.chat_category ul li{
    width: 80%;
    height: 30px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #e0e0e0;
    font-size: 13px;
    cursor: pointer;
    line-height: 30px;
    color: #888;
    text-align: center;
}

.chat_category li:hover{
    background: #83c76d;
    color: #fff;
}
.chat_category li.active{
    background: #83c76d;
    color: #fff;
}

.tags{
 margin: 20px;
    bottom: 0px;
    display: block;
    width: 120%
}
.tags li{
    padding: 5px 10px;
    border-radius: 40px;
    border: 1px solid rgb(3, 117, 208);
    margin: 5px;
    display: inline-block;
    color: rgb(3, 117, 208);
    cursor: pointer;

}
.fab_field {
  width: 100%;
  display: inline-block;
  text-align: center;
  background: #fff;
  border-top: 1px solid #eee;
  border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

}
.fab_field2 {
    bottom: 0px;
    position: absolute;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
        z-index: 999;
  }

.fab_field a {
  display: inline-block;
  text-align: center;
}

#fab_camera {
  float: left;
  background: rgba(0, 0, 0, 0);
}

#fab_send {
  float: right;
  background: rgba(0, 0, 0, 0);
}

.fab_field .fab {
  width: 35px;
  height: 35px;
  box-shadow: none;
  margin: 5px;
}

.fab_field .fab>i {
  font-size: 1.6em;
  line-height: 35px;
  color: #bbb;
}
.fab_field .fab>i:hover {
  color: #42a5f5;
}
.chat_conversion {

}

.chat_converse {
  position: relative;
    background: #fff;
    margin: 0px 0 0px 0;
    height: 300px;
    min-height: 0;
    font-size: 12px;
    line-height: 18px;
    overflow-y: auto;
    width: 100%;
    float: right;
    padding-bottom: 100px;
}
.chat_converse2{
      height: 100%;
    max-height: 800px
}
.chat_list {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.chat_list .chat_list_item {
  opacity: 0;
  visibility: hidden;
}

.chat .chat_converse .chat_msg_item {
  position: relative;
  margin: 8px 0 15px 0;
  padding: 8px 10px;
  max-width: 60%;
  display: block;
  word-wrap: break-word;
  border-radius: 3px;
  -webkit-animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  animation: zoomIn .5s cubic-bezier(.42, 0, .58, 1);
  clear: both;
  z-index: 999;
}
.status {
    margin: 45px -50px 0 0;
    float: right;
    font-size: 11px;
    opacity: 0.3;
}
.status2 {
    margin: -10px 20px 0 0;
    float: right;
    display: block;
    font-size: 11px;
    opacity: 0.3;
}
.chat .chat_converse .chat_msg_item .chat_avatar {
  position: absolute;
  top: 0;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin .chat_avatar {
  left: -52px;
  background: rgba(0, 0, 0, 0.03);
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user .chat_avatar {
  right: -52px;
  background: rgba(0, 0, 0, 0.6);
}

.chat .chat_converse .chat_msg_item .chat_avatar, .chat_avatar img{
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin {
  margin-left: 19px;
  float: left;
  background: rgba(0, 0, 0, 0.03);
  color: #666;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_user {
  margin-right: 20px;
  float: right;
  background: #42a5f5;
  color: #eceff1;
}

.chat .chat_converse .chat_msg_item.chat_msg_item_admin:before {
  content: '';
  position: absolute;
  top: 15px;
  left: -12px;
  z-index: 998;
  border: 6px solid transparent;
  border-right-color: rgba(255, 255, 255, .4);
}

.chat_form .get-notified label{
    color: #077ad6;
    font-weight: 600;
    font-size: 11px;
}

input {
  position: relative;
  /* width: 90%; */
  /* font-family: 'Roboto'; */
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #4b4b4b;
  /* -webkit-font-smoothing: antialiased; */
  /* font-smoothing: antialiased; */
  outline: none;
  background: #fff;
  display: inline-block;
  resize: none;
  /* padding: 5px; */
  border-radius: 3px;
}
.chat_form .get-notified input {
  margin: 2px 0 0 0;
  border: 1px solid #83c76d;
}
.chat_form .get-notified i {
    background: #83c76d;
    width: 30px;
    height: 32px;
    font-size: 18px;
    color: #fff;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    margin: 2px 0 0 -30px;
    position: absolute;
    border-radius: 3px;
}
.chat_form .message_form {
  margin: 10px 0 0 0;
}
.chat_form .message_form input{
  margin: 5px 0 5px 0;
  border: 1px solid #e0e0e0;
}
.chat_form .message_form textarea{
  margin: 5px 0 5px 0;
  border: 1px solid #e0e0e0;
  position: relative;
  width: 90%;
  /* font-family: 'Roboto'; */
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: #4b4b4b;
  /* -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; */
  outline: none;
  background: #fff;
  display: inline-block;
  resize: none;
  padding: 5px;
  border-radius: 3px;
}
.chat_form .message_form button{
    margin: 5px 0 5px 0;
    border: 1px solid #e0e0e0;
    position: relative;
    width: 95%;
    /* font-family: 'Roboto'; */
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
    /* -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased; */
    outline: none;
    background: #fff;
    display: inline-block;
    resize: none;
    padding: 5px;
    border-radius: 3px;
    background: #83c76d;
    cursor: pointer;
}
strong.chat_time {
  padding: 0 1px 1px 0;
  font-weight: 500;
  font-size: 8px;
  display: block;
}

/*Chatbox scrollbar*/

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  margin: 2px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
/*Element state*/

.is-active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.is-float {
  box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
}

.is-loading {
  display: block;
  -webkit-animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
  animation: load 1s cubic-bezier(0, .99, 1, 0.6) infinite;
}
/*Animation*/

/* @-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes load {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0.0;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
} */
/* SMARTPHONES PORTRAIT */

@media only screen and (min-width: 300px) {
  .chat {
    width: 250px;
  }
}
/* SMARTPHONES LANDSCAPE */

@media only screen and (min-width: 480px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/* TABLETS PORTRAIT */

@media only screen and (min-width: 768px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/* TABLET LANDSCAPE / DESKTOP */

@media only screen and (min-width: 1024px) {
  .chat {
    width: 300px;
  }
  .chat_field {
    width: 65%;
  }
}
/*Color Options*/



.blue .fab {
  background: #42a5f5;
  color: #fff;
}



.blue .chat {
  background: #42a5f5;
  color: #999;
}


/* Ripple */

.ink {
  display: block;
  position: absolute;
  background: rgba(38, 50, 56, 0.4);
  border-radius: 100%;
  /* -moz-transform: scale(0);
  -ms-transform: scale(0);
  webkit-transform: scale(0);
  -webkit-transform: scale(0); */
          transform: scale(0);
}
/*animation effect*/

.ink.animate {
  -webkit-animation: ripple 0.5s ease-in-out;
          animation: ripple 0.5s ease-in-out;
}

@-webkit-keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/
  
  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    -webkit-transform: scale(5);
            transform: scale(5);
  }
}

@keyframes ripple {
  /*scale the element to 250% to safely cover the entire link and fade it out*/
  
  100% {
    opacity: 0;
    -moz-transform: scale(5);
    -ms-transform: scale(5);
    webkit-transform: scale(5);
    -webkit-transform: scale(5);
            transform: scale(5);
  }
}
::-webkit-input-placeholder { /* Chrome */
  color: #bbb;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #bbb;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #bbb;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: #bbb;
}
#menu-mobile-1{
  visibility: hidden;
  /* transform: translateX(280p); */
}

.container {
  max-width: 133rem !important;
  margin: 0 auto !important;
  padding: 0 1.5rem !important;
}
.mi2{
  margin: 10px 0px !important;
  padding: 0px !important ;
}
@media only screen and (min-width: 280px) and (max-width: 599px) {

  .efeefefree{
      color: black !important;
      margin-bottom: 174px !important;
      /* color: white !important; */
  }
  .hero-content__text {
        margin-top: -10rem !important;
  }
}
@media  only screen and (min-width: 530px) and (max-width: 729px){
  .mi2{
    margin: 10px 0px !important;
    padding: 0px !important ;
  }
}
@media only screen and (min-width: 280px) and (max-width: 599px) {
  .der1{
    width: 275px !important;
    height: 99px !important;
    max-width: 150px !important;
    object-fit: cover;
  }}
  
@media  only screen and (min-width: 120px) and (max-width: 529px){
  
    .kkkkkppokpo{
        display: flex !important
    }
  
  .mi2{
    margin: 0px !important;
    padding: 0px !important ;
  }
}



/* @import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');
@import url('https://fonts.googleapis.com/css?family=Catamaran:400,800'); */
.error-container {
  text-align: center;
  font-size: 56px;
  /* font-size: 106px; */
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 70px 15px 0px 15px;
  /* margin: 70px 15px; */
}
.error-container > span {
  display: inline-block;
  position: relative;
}
.error-container > span.four {
  width: 101px;
  height: 43px;
  border-radius: 999px;
  background:
    linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
    linear-gradient(105deg, transparent 0%, transparent 40%, rgba(0, 0, 0, 0.06) 41%, rgba(0, 0, 0, 0.07) 76%, transparent 77%, transparent 100%),
    linear-gradient(to right, #d89ca4, #e27b7e);
}
.error-container > span.four:before,
.error-container > span.four:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
}
.error-container > span.four:before {
  width: 37px;
  height: 150px;
  left: 58px;
  bottom: -41px;
  background:
    linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
    linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
    linear-gradient(to top, #99749D, #B895AB, #CC9AA6, #D7969E, #E0787F);
}
.error-container > span.four:after {
  width: 129px;
  height: 43px;
  transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(to right, #99749D, #B895AB, #CC9AA6, #D7969E, #E0787F);
}

.error-container > span.zero {
  vertical-align: text-top;
  width: 121px;
  height: 126px;
  border-radius: 999px;
  background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%,  transparent 51%, transparent 100%),
    linear-gradient(to top right, #99749D, #99749D, #B895AB, #CC9AA6, #D7969E, #ED8687, #ED8687);
  overflow: hidden;
  animation: bgshadow 5s infinite;
}
.error-container > span.zero:before {
  content: '';
  display: block;
  position: absolute;
  transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background:
    linear-gradient(95deg, transparent 0%, transparent 8%, rgba(0, 0, 0, 0.07) 9%, transparent 50%, transparent 100%),
    linear-gradient(85deg, transparent 0%, transparent 19%, rgba(0, 0, 0, 0.05) 20%, rgba(0, 0, 0, 0.07) 91%, transparent 92%, transparent 100%);
}
.error-container > span.zero:after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 33px;
  bottom: 33px;
  background: #FDFAF5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
    
@keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }
  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
}

.zoom-area { 
  max-width: 490px;
  margin: 5px auto 4px;
  font-size: 19px;
  text-align: center;
  color : black;
}
.link-container {
  text-align: center;
}
.more-link {
  text-transform: uppercase;
  font-size: 13px;
    background-color: #de7e85;
    padding: 10px 15px;
    border-radius: 0;
    color: rgb(255, 255, 255) !important;
    font-weight:  bold;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
  margin-top: 50px;
  letter-spacing: 1px;
}
input[placeholder], [placeholder], *[placeholder] {
  color: black !important;
}