@use "../global" as *;

.hero-section {
  width: 100%;
  height: 97vh;
  background-color: $bg-gray;
  position: relative;
}

.bg-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

 
}

.hero-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 800px) {
    justify-content: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    z-index: 3;
    max-width: 50rem;
    margin-top: 5rem;

    @media (max-width: 800px) {
      text-align: center;
      align-items: center;
    }

    h4 {
      font-size: $h4-size;
      font-family: $text-font;
      color: $text-black;
    }

    h1 {
      font-size: $h1-size;
      font-family: $titles-font;
      font-weight: 700;
      color: $text-black;
      line-height: 1.2;
      margin-top: 1rem;
      margin-bottom: 2.3rem;

      span {
        color: $text-orange;
      }
    }

    p {
      font-size: $p-size;
      font-family: $text-font;
      line-height: 1.6;
      color: $text-gray;
      margin-bottom: 4rem;
    }

    &__btns {
      display: flex;
      gap: 2rem;
      font-size: $p-size;
      font-family: $text-font;

      @media (max-width: 450px) {
        flex-direction: column;
      }

      a {
        text-decoration: none;
        color: white;
        font-weight: 700;
      }

      &__book-ride {
        background-color: $text-orange;
        padding: 1.8rem 3rem;
        border-radius: 0.3rem;
        box-shadow: 0 10px 15px 0 rgb(255 83 48 / 35%);
        transition: all 0.3s;
        border: 2px solid $text-orange;

        @media (max-width: 450px) {
          padding: 1.8rem 8rem;
        }

        &:hover {
          box-shadow: 0 10px 15px 0 rgb(255 83 48 / 60%);
          background-color: #fa4226;
        }
      }

      &__learn-more {
        background-color: $text-black;
        padding: 1.8rem 3rem;
        border-radius: 0.3rem;
        border: 2px solid $text-black;
        transition: all 0.3s;

        &:hover {
          background-color: transparent;
          color: $text-black;
        }
      }
    }
  }

  &__car-img {
    z-index: 2;
    position: absolute;
    right: 0;
    width: 65%;
    margin-top: 5rem;

    // @media (max-width: 800px) {
    //   display: none;
    // }
  }
}

.scroll-up {
  position: fixed;
  font-size: 2.5rem;
  color: white;
  background-color: $text-orange;
  border: 3px solid white;
  width: 2rem;
  height: 2rem;
  bottom: 5rem;
  right: 5rem;
  z-index: 20;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
}

.show-scroll {
  display: flex;
}
