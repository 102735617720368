.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* display: none; */
    z-index: 999999;
  }
  
  .popup-content {
    position: relative;
    margin: 5% auto;
    width: 50%;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height:auto;
  }
  @media only screen and (min-width: 292px) and (max-width: 799px) {
      .popup-content {
          
          margin: 40% auto;
        width: 90% !important;
     
        height: auto !important;
      }

  }

  .close-icon {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .show-popup {
    display: block;
  }